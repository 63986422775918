import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '../ui/button';
import { Home, History, LogOut, Menu, X, Trophy, Settings, HelpCircle, ExternalLink, Heart, Book } from 'lucide-react'; // Added Book icon for Resources

function Navbar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavLink = ({ to, icon: Icon, children, external }) => {
    const linkProps = external
      ? { href: to, target: '_blank', rel: 'noopener noreferrer' }
      : { to };
    const Component = external ? 'a' : Link;

    return (
      <Component
        {...linkProps}
        className={`inline-flex items-center px-3 py-2 text-sm transition-colors
          ${location.pathname === to && !external
            ? 'text-yellow-500 border-b-2 border-yellow-500'
            : 'text-yellow-500/60 hover:text-yellow-500'}`}
      >
        <Icon className="w-4 h-4 mr-2" />
        {children}
      </Component>
    );
  };

  return (
    <nav className="bg-card-bg border-b border-poker-gold/30">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <NavLink to="/dashboard" icon={Home}>Dashboard</NavLink>
            <NavLink to="/history" icon={History}>History</NavLink>
            <NavLink to="/achievements" icon={Trophy}>Achievements</NavLink>
            <NavLink to="/profile/manage" icon={Settings}>Manage Profile</NavLink>
            <NavLink to="/support" icon={HelpCircle}>Support</NavLink>
            <NavLink to="/donate" icon={Heart}>Donate</NavLink>
            <NavLink to="/resources" icon={Book}>Resources</NavLink> {/* Added Resources link */}
            <NavLink to="https://pokerdebtsolver.com" icon={ExternalLink} external>
              SettleStack - Lite
            </NavLink>
          </div>

          {/* User Menu & Welcome */}
          <div className="hidden md:flex items-center space-x-4">
            {user && (
              <>
                <span className="text-sm text-poker-gold/60">
                  Welcome, {user.username}
                </span>
                <Button
                  variant="outline"
                  onClick={handleLogout}
                  className="button-enhanced inline-flex items-center bg-yellow-500/10 hover:bg-yellow-500/20 text-yellow-500"
                >
                  <LogOut className="w-4 h-4 mr-2" />
                  Logout
                </Button>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <Button
              variant="ghost"
              className="text-poker-gold hover:text-poker-gold/80"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span className="sr-only">Open menu</span>
              {showMenu ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </Button>
          </div>
        </div>

        {/* Mobile Menu */}
        {showMenu && (
          <div className="md:hidden border-t border-poker-gold/30">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/dashboard"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <Home className="w-4 h-4 mr-2" />
                  Dashboard
                </div>
              </Link>
              <Link
                to="/history"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <History className="w-4 h-4 mr-2" />
                  History
                </div>
              </Link>
              <Link
                to="/achievements"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <Trophy className="w-4 h-4 mr-2" />
                  Achievements
                </div>
              </Link>
              <Link
                to="/profile/manage"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <Settings className="w-4 h-4 mr-2" />
                  Manage Profile
                </div>
              </Link>
              <Link
                to="/support"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <HelpCircle className="w-4 h-4 mr-2" />
                  Support
                </div>
              </Link>
              <Link
                to="/donate"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <Heart className="w-4 h-4 mr-2" />
                  Donate
                </div>
              </Link>
              <Link
                to="/resources"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <Book className="w-4 h-4 mr-2" />
                  Resources
                </div>
              </Link>
              <a
                href="https://pokerdebtsolver.com"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-3 py-2 text-base text-poker-gold/60 hover:text-poker-gold hover:bg-black/20 rounded-md"
                onClick={() => setShowMenu(false)}
              >
                <div className="flex items-center">
                  <ExternalLink className="w-4 h-4 mr-2" />
                  SettleStack - Lite
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
