// src/App.jsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Context Providers
import { AuthProvider } from './contexts/AuthContext';
import { GameProvider } from './contexts/GameContext';

// UI Providers
import { ToastProvider } from './components/ui/use-toast';

// Authentication Components
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';

// Public-Facing Pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Support from './components/auth/Support';
import DonatePage from './pages/DonatePage';
import Resources from './pages/Resources';
import Glossary from './pages/Glossary';
import PlayerTypes from './pages/PlayerTypes';
import HandRankings from './pages/HandRankings';
import EquityAndOdds from './pages/EquityAndOdds';
import CommonMistakes from './pages/CommonMistakes';

// Protected Route Component
import ProtectedRoute from './components/auth/ProtectedRoute';

// Layout Components
import Navbar from './components/layout/Navbar';

// Main Application Pages
import Dashboard from './components/Dashboard';
import HistoryPage from './components/history/HistoryPage';
import SessionAnalysis from './components/history/SessionAnalysis';
import ManageProfile from './pages/ManageProfile';
import SelectProfilePicture from './pages/SelectProfilePicture';
import GameSetup from './components/game/GameSetup';
import GameDashboard from './components/game/GameDashboard';
import SubmitBalances from './components/game/SubmitBalances';
import AchievementsPage from './components/Achievements';

// AppLayout Component to Include Navbar
const AppLayout = ({ children }) => {
  if (!children) return null;

  return (
    <>
      <Navbar />
      <main className="dashboard-wrapper">{children}</main>
    </>
  );
};

// App Component
function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <GameProvider>
          <Router>
            <div className="min-h-screen bg-poker-green-light">
              <Routes>
                {/* Public Routes - No Layout Wrapper */}
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/support" element={<Support />} />

                {/* Resources Routes */}
                <Route path="/resources" element={<Resources />} />
                <Route path="/resources/glossary" element={<Glossary />} />
                <Route path="/resources/player-types" element={<PlayerTypes />} />
                <Route path="/resources/hand-rankings" element={<HandRankings />} />
                <Route path="/resources/equity-and-odds" element={<EquityAndOdds />} />
                <Route path="/resources/common-mistakes" element={<CommonMistakes />} />


                {/* Authentication Routes */}
                <Route path="/login" element={<LoginForm />} />
                <Route path="/register" element={<RegisterForm />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                {/* Protected Routes - With Layout Wrapper */}
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <Dashboard />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Donate Page */}
                <Route
                  path="/donate"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <DonatePage />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* History Routes */}
                <Route
                  path="/history/*"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <HistoryPage />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Session Analysis */}
                <Route
                  path="/history/analyze"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <SessionAnalysis />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Game Routes */}
                <Route
                  path="/game/new"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <GameSetup />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/game/:gameId/dashboard"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <GameDashboard />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/game/:gameId/submit-balances"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <SubmitBalances />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Profile Routes */}
                <Route
                  path="/profile/manage"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <ManageProfile />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/profile/manage/select-picture"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <SelectProfilePicture />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Achievements */}
                <Route
                  path="/achievements"
                  element={
                    <ProtectedRoute>
                      <AppLayout>
                        <AchievementsPage />
                      </AppLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Catch-All Route for 404 */}
                <Route
                  path="*"
                  element={
                    <div className="flex items-center justify-center min-h-screen bg-poker-green-light">
                      <h1 className="text-4xl text-yellow-300">404 - Page Not Found</h1>
                    </div>
                  }
                />
              </Routes>
            </div>
          </Router>
        </GameProvider>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
