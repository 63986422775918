// frontend/src/components/auth/LoginForm.jsx

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { Label } from '../ui/label';
import { useToast } from '../ui/use-toast';
import logo from '../../assets/logo.png';
import { motion } from 'framer-motion';
import PokerLoadingTransition from './LoadingTransition'; 
import { FaSpinner } from 'react-icons/fa';

function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const { toast } = useToast();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await login(formData.email, formData.password);
      setShowTransition(true);
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'Failed to login. Please check your credentials.');

      toast({
        title: "Login Failed ♣️",
        description: err.message || "Invalid credentials",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PokerLoadingTransition
        isVisible={showTransition}
        chipCount={20}
        cardCount={12}
        onComplete={() => {
          setShowTransition(false);
          toast({
            title: "Welcome back! ♠️",
            description: "Successfully logged in",
            variant: "success",
          });
          navigate('/dashboard', { replace: true });
        }}
      />

      <div className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col items-center justify-center p-4">
        <Card className="bg-card-bg border-poker-gold backdrop-blur-sm w-full max-w-md">
          <CardContent className="pt-6 pb-8 px-6">
            <div className="flex flex-col items-center">
              {/* Enlarged Logo */}
              <img src={logo} alt="SettleStack Logo" className="h-24 w-24 mb-4" />

              <h2 className="text-2xl font-bold text-poker-gold mb-6" aria-label="Sign In to SettleStack">
                Sign In to SettleStack
              </h2>

              {error && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.2 }}
                  className="w-full mb-6"
                >
                  <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                </motion.div>
              )}

              <form onSubmit={handleSubmit} className="w-full space-y-4" aria-describedby="signin-form">
                <div className="space-y-2">
                  <Label htmlFor="email" className="text-poker-gold">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    aria-label="Email Address"
                    className="bg-black/20 border-poker-gold text-yellow-100 placeholder-yellow-200 focus:ring-poker-gold"
                    disabled={isLoading}
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="password" className="text-poker-gold">Password</Label>
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    aria-label="Password"
                    className="bg-black/20 border-poker-gold text-yellow-100 placeholder-yellow-200 focus:ring-poker-gold"
                    disabled={isLoading}
                  />
                </div>

                <Button
                  type="submit"
                  className="w-full bg-poker-gold text-poker-dark hover:bg-yellow-400 flex items-center justify-center space-x-2"
                  disabled={isLoading}
                  aria-label="Sign In"
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className="animate-spin" />
                      <span>Signing in...</span>
                    </>
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </form>

              <div className="flex flex-col items-center gap-2 mt-6 text-sm text-poker-gold">
                <div>
                  New to SettleStack?{' '}
                  <Button
                    variant="link"
                    className="p-0 h-auto text-poker-gold hover:text-yellow-400"
                    onClick={() => navigate('/register')}
                    aria-label="Create an account"
                  >
                    Create an account »
                  </Button>
                </div>
                <Button
                  variant="link"
                  className="text-poker-gold hover:text-yellow-400"
                  onClick={() => navigate('/forgot-password')}
                  aria-label="Forgot your password?"
                >
                  Forgot your password?
                </Button>
              </div>

              {/* What is SettleStack Section */}
              <div className="mt-8 w-full">
                <h3 className="text-xl font-semibold text-poker-gold mb-2" aria-label="What is SettleStack?">
                  What is SettleStack? 🃏
                </h3>
                <p className="text-yellow-200 text-sm leading-relaxed">
                  SettleStack is your go-to solution for a smooth poker night. After a game full of big bets and bold bluffs, figuring out who owes who can be the hardest part—especially if the drinks were flowing. That’s where we come in.
                  <br /><br />
                  <strong>Here’s how SettleStack makes settling up easy:</strong>
                  <ul className="list-disc pl-6 mt-2 mb-4">
                    <li><strong>Host a Game:</strong> Start a game with a join code and invite your group.</li>
                    <li><strong>Set a Timer:</strong> Choose how long the game lasts.</li>
                    <li><strong>Count the Chips:</strong> Once the game ends, the host enters everyone’s final chip totals.</li>
                    <li><strong>Get the Results:</strong> We calculate exact payouts so everyone knows who owes who—no math, no confusion.</li>
                  </ul>
                  <strong>And there’s more:</strong>
                  <ul className="list-disc pl-6 mt-2">
                    <li><strong>Track Your Stats:</strong> See how you stack up and improve your strategy.</li>
                    <li><strong>Unlock Achievements:</strong> Earn milestones and rewards.</li>
                    <li><strong>Personalize Your Profile:</strong> Stand out with a custom profile picture.</li>
                  </ul>
                  <br />
                  Whether you’re playing for big stakes or just bragging rights, SettleStack keeps the focus on the fun and takes care of the rest. Ready to up your game? 🃏
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default LoginForm;
