import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import UUID

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const toast = ({ variant = 'info', title, description }) => {
    const id = uuidv4(); // Generate a unique ID
    setToasts((prev) => [...prev, { id, variant, title, description }]);

    // Automatically remove the toast after 3 seconds
    setTimeout(() => {
      setToasts((prev) => prev.filter((t) => t.id !== id));
    }, 3000);
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <div className="fixed bottom-4 right-4 space-y-2">
        {toasts.map(({ id, variant, title, description }) => (
          <Toast key={id} variant={variant} title={title} description={description} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

const Toast = ({ variant, title, description }) => {
  const toastStyles = {
    info: 'bg-blue-500',
    success: 'bg-green-500',
    warning: 'bg-yellow-500',
    error: 'bg-red-500',
  };

  return (
    <div
      className={`p-4 rounded shadow-lg text-white ${toastStyles[variant]} flex flex-col`}
    >
      {title && <strong className="text-lg">{title}</strong>}
      {description && <p className="text-sm">{description}</p>}
    </div>
  );
};
