// src/components/ui/alert.jsx
import React from 'react';

export const Alert = ({ children }) => (
  <div className="alert-styles">
    {children}
  </div>
);

export const AlertDescription = ({ children }) => (
  <div className="alert-description-styles">
    {children}
  </div>
);

export default Alert;
