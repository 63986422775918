import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import { motion } from 'framer-motion';
import { useToast } from './ui/use-toast';
import axios from '../utils/axios';
import { 
  PlayCircle, 
  PauseCircle, 
  DollarSign, 
  Plus,
  Users,
  Trophy,
  Loader2,
  User,
  Settings
} from 'lucide-react';
import { useGame } from '../contexts/GameContext';
import { calculateStatistics, formatCurrency, formatDate } from '../utils/stats';
import { gameService } from '../services/game.service';
import PokerChipButton from './ui/PokerChipButton';
import logo from '../assets/logo.png'; // Import the logo at the top

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const { toast } = useToast();
  const { 
    userGames: games, 
    fetchUserGames, 
    createGame,
    loading: gameLoading, 
    error: gameError 
  } = useGame();
  const [error, setError] = useState(null);
  const [joinCode, setJoinCode] = useState('');
  const [joiningGame, setJoiningGame] = useState(false);

  const DEBUG = true;  // Toggle this to control logging

  const debugLog = (...args) => {
  if (DEBUG) {
    console.log(`[${new Date().toISOString()}]`, ...args);
  }
};

  const getImageUrl = (path) => {
    if (!path) return '';
    const cleanPath = path.replace(/^\/+/, '');
    return `${axios.defaults.baseURL}/${cleanPath}`;
  };

  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/login');
    }
  }, [user, authLoading, navigate]);

  const [profileStats, setProfileStats] = useState({
    totalCompletedGames: 0,
    biggestWin: 0,
    bestGameDate: null,
    netProfit: 0  // Add this line
  });
  
  useEffect(() => {
    const fetchAndCalculateStats = async () => {
      if (user?.id) {
        try {
          const games = await gameService.getUserGames();
          
          if (!Array.isArray(games)) {
            console.error('Expected array of games, received:', typeof games);
            throw new Error('Invalid games data structure');
          }
          
          const calculatedStats = calculateStatistics(games, user.id);
          
          // Ensure we're setting raw numbers, not formatted strings
          setProfileStats({
            totalCompletedGames: calculatedStats.totalCompletedGames || 0,
            biggestWin: calculatedStats.biggestWin || 0,
            bestGameDate: calculatedStats.bestGameDate,
            netProfit: typeof calculatedStats.netProfit === 'string' 
              ? parseFloat(calculatedStats.netProfit.replace(/[^0-9.-]+/g, ''))
              : calculatedStats.netProfit || 0
          });
        } catch (error) {
          console.error('Stats fetch error:', error);
          setError("Unable to fetch game statistics");
        }
      }
    };
  
    fetchAndCalculateStats();
  }, [user?.id]); 


  const handleJoinGame = async () => {
    if (!joinCode) {
      toast({
        title: "Error",
        description: "Join code is required",
        variant: "destructive",
      });
      return;
    }
  
    setJoiningGame(true);
  
    try {
      const result = await gameService.joinGame({
        joinCode: joinCode.trim(),
        name: user.username, // Use the username of the logged-in user
      });
  
      navigate(`/game/${result.player.game_id}/dashboard`); // Navigate to the game dashboard
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to join game. Please try again.",
        variant: "destructive",
      });
    } finally {
      setJoiningGame(false);
    }
  };
  
  if (authLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="w-8 h-8 text-yellow-500 animate-spin" />
          <p className="text-yellow-500">Loading...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const cardVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };


  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="dashboard-wrapper"
    >
      {/* Logo Section */}
      <div className="dashboard-container max-w-7xl mx-auto px-4 py-8">
        <motion.div 
          className="flex justify-center"
          animate={{ 
            rotateY: [0, 360],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatDelay: 5
          }}
        >
          <img
            src={logo}
            alt="Poker Debt Solver Logo"
            className="h-48 w-auto"
          />
        </motion.div>
      </div>
  
      {/* Main Dashboard Content */}
      <div className="dashboard-container max-w-7xl mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Profile Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }}>
          <Card className="dashboard-card flex flex-col justify-between p-6">
            <CardHeader>
              <CardTitle className="text-center text-2xl font-bold text-yellow-500">
                <User className="card-icon" />
                Profile
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow text-center">
              <div className="w-24 h-24 mx-auto rounded-full flex items-center justify-center bg-black/50 border-2 border-yellow-500 mb-4 overflow-hidden">
                {user.profile_picture ? (
                  <img
                    src={getImageUrl(user.profile_picture)}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <User className="w-12 h-12 text-yellow-500" />
                )}
              </div>
              <h3 className="text-lg font-semibold">{user.username}</h3>
              <p className="text-sm text-gray-500">{user.email}</p>
              <div className="mt-4">
                <div className="text-sm font-medium text-yellow-500">
                  <DollarSign className="inline-block mr-2" />
                  Venmo: {user.venmo_handle || 'Not Set'}
                </div>
                <div className="mt-4 text-sm">
                  <p>Completed Games: <span className="font-bold">{profileStats.totalCompletedGames}</span></p>
                  <p>Best Game: <span className="font-bold">{profileStats.biggestWin || '$0.00'}</span></p>
                  <p>Net Profit/Loss: <span className={`font-bold ${profileStats.netProfit >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                    {typeof profileStats.netProfit === 'number' 
                      ? formatCurrency(profileStats.netProfit)
                      : profileStats.netProfit || '$0.00'}
                  </span></p>
                  <p>Best Game Date: <span className="font-bold">
                    {profileStats.bestGameDate
                      ? new Date(profileStats.bestGameDate).toLocaleDateString()
                      : 'N/A'}
                  </span></p>
                </div>
              </div>
            </CardContent>
            <PokerChipButton
              onClick={() => navigate('/profile/manage')}
              variant="black"
              className="mt-4 w-full"
            >
              Manage Profile
            </PokerChipButton>
          </Card>
        </motion.div>
  
        {/* Create Game Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }}>
          <Card className="dashboard-card flex flex-col justify-between p-6">
            <CardHeader>
              <CardTitle className="text-center text-2xl font-bold text-yellow-500">
                <Plus className="card-icon" />
                Create Game
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow text-center">
              <p className="text-sm text-gray-500 mb-4">Start a new game for your group.</p>
              <PokerChipButton
                onClick={() => navigate('/game/new')}
                variant="green"
                className="w-full"
                disabled={gameLoading}
              >
                {gameLoading ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Creating...
                  </>
                ) : (
                  'Create New Game'
                )}
              </PokerChipButton>
            </CardContent>
          </Card>
        </motion.div>
  
        {/* Join Game Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }}>
          <Card className="dashboard-card flex flex-col justify-between p-6">
            <CardHeader>
              <CardTitle className="text-center text-2xl font-bold text-yellow-500">
                <Users className="card-icon" />
                Join Game
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow text-center">
              <p className="text-sm text-gray-500 mb-4">Enter the code to join an existing game.</p>
              <Input
                type="text"
                placeholder="Enter game code"
                className="input-enhanced mb-4"
                value={joinCode}
                onChange={(e) => setJoinCode(e.target.value.toUpperCase())}
              />
              <PokerChipButton
                variant="blue"
                className="w-full"
                onClick={handleJoinGame}
                disabled={joiningGame}
              >
                {joiningGame ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Joining...
                  </>
                ) : (
                  'Join Game'
                )}
              </PokerChipButton>
            </CardContent>
          </Card>
        </motion.div>
  
        {/* Achievements Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5 }}>
          <Card className="dashboard-card flex flex-col justify-between p-6">
            <CardHeader>
              <CardTitle className="text-center text-2xl font-bold text-yellow-500">
                <Trophy className="card-icon" />
                Achievements
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow text-center">
              <p className="text-sm text-gray-500 mb-4">View your achievements and milestones.</p>
              <PokerChipButton
                onClick={() => navigate('/achievements')}
                variant="red"
                className="w-full"
              >
                View Achievements
              </PokerChipButton>
            </CardContent>
          </Card>
        </motion.div>
      </div>
  
      {/* Error Alert */}
      {(error || gameError) && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <Alert variant="destructive" className="error-alert mt-4">
            <AlertDescription>{error || gameError}</AlertDescription>
          </Alert>
        </motion.div>
      )}

      {/* Footer with Copyright Notice */}
      <footer className="mt-auto py-6 px-4 text-center text-sm text-gray-500 border-t border-gray-800 bg-black/20">
        <p className="max-w-4xl mx-auto">
          © 2024 SettleStack. All rights reserved. All content, including but not limited to text, graphics, logos, and software, 
          is the property of SettleStack and is protected by international copyright laws. Unauthorized use, reproduction, or 
          distribution of any content without explicit permission is prohibited.
        </p>
      </footer>
    </motion.div>
  );
};

export default Dashboard;
