export const predefinedProfilePictures = {
  default: '/assets/profile-pictures/qv615axg.png', // Default profile picture
  options: [
    '/assets/profile-pictures/0rcecx6x.png',
    '/assets/profile-pictures/2mogfnni.png',
    '/assets/profile-pictures/8ibu4pel.png',
    '/assets/profile-pictures/18grgwv8.png',
    '/assets/profile-pictures/a7irhmv5.png',
    '/assets/profile-pictures/boikf37d.png',
    '/assets/profile-pictures/ejykhqay.png',
    '/assets/profile-pictures/iaiy4g6n.png',
    '/assets/profile-pictures/khgj9n68.png',
    '/assets/profile-pictures/oip14wgx.png',
    '/assets/profile-pictures/ptpgfczu.png',
    '/assets/profile-pictures/q6jie3by.png',
    '/assets/profile-pictures/qv615axg.png',
    '/assets/profile-pictures/rkt1lfi4.png',
    '/assets/profile-pictures/t4ifj7u7.png',
    '/assets/profile-pictures/w50j9l7c.png',
    '/assets/profile-pictures/wftsl3jq.png',
  ],
};