// src/components/auth/PrivacyPolicy.jsx
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-900/50 p-4">
      <Card className="w-full max-w-4xl bg-green-800 border-yellow-500 shadow-lg overflow-auto max-h-[90vh]">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center text-yellow-500">Privacy Policy</CardTitle>
          <p className="text-center text-yellow-500">Last Updated: December 2, 2024</p>
        </CardHeader>

        <CardContent className="text-yellow-500 overflow-y-auto">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">1. Introduction</h2>
            <p>
              Welcome to <strong>SettleStack</strong> ("we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, store, and protect your information when you use our web application located at [yourwebsite.com] (the "Service").
            </p>

            <h2 className="text-xl font-semibold">2. Information We Collect</h2>
            <p>
              We may collect and process the following types of information:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>Personal Data:</strong> Information that can be used to identify you, such as your name, email address, Venmo handle, and other contact details.</li>
              <li><strong>Usage Data:</strong> Information about how you use the Service, including game data, settlement history, and interaction logs.</li>
              <li><strong>Payment Information:</strong> Details necessary to process payments via Venmo.</li>
              <li><strong>Cookies and Tracking Technologies:</strong> Data collected through cookies and similar technologies to enhance your experience on our Service.</li>
            </ul>

            <h2 className="text-xl font-semibold">3. How We Use Your Information</h2>
            <p>
              We use the collected information for the following purposes:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>Service Provision:</strong> To operate, maintain, and improve our Service.</li>
              <li><strong>Payment Processing:</strong> To facilitate transactions through Venmo.</li>
              <li><strong>Communication:</strong> To send you updates, respond to your inquiries, and provide customer support.</li>
              <li><strong>Analytics:</strong> To analyze usage patterns and enhance user experience.</li>
              <li><strong>Security:</strong> To protect against fraudulent activities and ensure the security of our Service.</li>
            </ul>

            <h2 className="text-xl font-semibold">4. Data Sharing and Disclosure</h2>
            <p>
              We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>Third-Party Services:</strong> We may share your information with trusted third-party service providers who assist us in operating our Service, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
              <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
              <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
            </ul>

            <h2 className="text-xl font-semibold">5. Data Security</h2>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>

            <h2 className="text-xl font-semibold">6. Your Rights</h2>
            <p>
              Depending on your location, you may have the following rights regarding your personal data:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>Access:</strong> You can request access to the personal data we hold about you.</li>
              <li><strong>Correction:</strong> You can request that we correct any inaccurate or incomplete data.</li>
              <li><strong>Deletion:</strong> You can request the deletion of your personal data under certain conditions.</li>
              <li><strong>Objection:</strong> You can object to the processing of your personal data under certain conditions.</li>
              <li><strong>Data Portability:</strong> You can request the transfer of your personal data to another party.</li>
            </ul>
            <p>
              To exercise these rights, please contact us at <a href="mailto:settlestack@gmail.com" className="underline hover:text-yellow-400">settlestack@gmail.com</a>.
            </p>

            <h2 className="text-xl font-semibold">7. Cookies and Tracking Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
            </p>

            <h2 className="text-xl font-semibold">8. Children's Privacy</h2>
            <p>
              Our Service is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If you become aware that a child has provided us with personal information, please contact us immediately at <a href="mailto:settlestack@gmail.com" className="underline hover:text-yellow-400">settlestack@gmail.com</a>, and we will take steps to remove such information from our servers.
            </p>

            <h2 className="text-xl font-semibold">9. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>
            <p>
              Changes to this Privacy Policy are effective when they are posted on this page.
            </p>

            <h2 className="text-xl font-semibold">10. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>Email:</strong> <a href="mailto:settlestack@gmail.com" className="underline hover:text-yellow-400">settlestack@gmail.com</a></li>
            </ul>
          </div>
        </CardContent>

        <CardFooter className="flex justify-center">
          <Button
            onClick={() => navigate('/')}
            className="bg-yellow-500 text-black hover:bg-yellow-400"
          >
            Back to Home
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default PrivacyPolicy;
