// src/components/auth/Support.jsx

import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../ui/card';
import { Button } from '../ui/button';

function Support() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-900/50 p-4">
      <Card className="w-full max-w-4xl bg-green-800 border-yellow-500 shadow-lg overflow-auto max-h-[90vh]">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center text-yellow-500">Support</CardTitle>
          <p className="text-center text-yellow-500">Last Updated: December 2, 2024</p>
        </CardHeader>

        <CardContent className="text-yellow-500 overflow-y-auto">
          <div className="space-y-6">
            {/* Links to Policies */}
            <div>
              <h2 className="text-xl font-semibold mb-2">Our Policies</h2>
              <ul className="list-disc list-inside space-y-1">
                <li>
                  <Link to="/privacy-policy" className="underline hover:text-yellow-400">
                    View our Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-service" className="underline hover:text-yellow-400">
                    View our Terms of Service
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact Information */}
            <div>
              <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
              <p>
                If you have any questions, concerns, or need assistance, please feel free to reach out to us:
              </p>
              <ul className="list-disc list-inside space-y-1">
                <li>
                  <strong>Email:</strong> <a href="mailto:settlestack@gmail.com" className="underline hover:text-yellow-400">settlestack@gmail.com</a>
                </li>
                {/* If you decide to add more contact methods in the future, you can include them here */}
              </ul>
            </div>
          </div>
        </CardContent>

        <CardFooter className="flex justify-center">
          <Button
            onClick={() => navigate('/dashboard')}
            className="bg-yellow-500 text-black hover:bg-yellow-400"
          >
            Back to Home
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Support;
