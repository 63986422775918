import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AlertCircle, TrendingUp, BarChart, Award } from 'lucide-react';
import { motion } from 'framer-motion';
import { gameService } from '../../services/game.service';
import { useAuth } from '../../contexts/AuthContext';
import { calculateStatistics } from '../../utils/stats';
import GameHistory from './GameHistory';
import PersonalStats from './PersonalStats';

const Overview = ({ games, userId }) => {
  const stats = calculateStatistics(games, userId);

  const recentGames = games.slice(0, 5).map((game) => {
    const player = game.players.find((p) => p.user_id === userId);
    const profit = player
      ? parseFloat(player.final_balance || 0) - parseFloat(player.buy_in || 0)
      : 0;

    return {
      date: game.start_time,
      profit: profit,
      status: game.status,
    };
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      {/* Stats Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-green-900 border-2 border-yellow-500 rounded-lg p-4 sm:p-6"
        >
          <h3 className="text-yellow-500 text-lg sm:text-xl font-bold mb-2">Total Games</h3>
          <p className="text-3xl sm:text-4xl text-white">{stats.totalGames}</p>
        </motion.div>
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="bg-green-900 border-2 border-yellow-500 rounded-lg p-4 sm:p-6"
        >
          <h3 className="text-yellow-500 text-lg sm:text-xl font-bold mb-2">Best Game</h3>
          <p className="text-xl sm:text-2xl text-white mb-1">{stats.biggestWin}</p>
          <p className="text-gray-300 text-xs sm:text-sm">{stats.bestGameDate}</p>
        </motion.div>
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-green-900 border-2 border-yellow-500 rounded-lg p-4 sm:p-6"
        >
          <h3 className="text-yellow-500 text-lg sm:text-xl font-bold mb-2">Win Rate</h3>
          <p className="text-3xl sm:text-4xl text-white">{stats.winRate}</p>
        </motion.div>
      </div>

      {/* Recent Games Table */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="bg-green-900 border-2 border-yellow-500 rounded-lg p-4 sm:p-6"
      >
        <h3 className="text-yellow-500 text-lg sm:text-xl font-bold mb-3">Recent Games</h3>
        <div className="space-y-3">
          {recentGames.map((game, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 + index * 0.1 }}
              className="flex justify-between items-center border-b border-green-800 pb-2"
            >
              <div className="text-white text-sm sm:text-base">
                {new Date(game.date).toLocaleString('en-US', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </div>
              <div
                className={`text-sm sm:text-lg font-semibold ${
                  game.profit > 0
                    ? 'text-green-400'
                    : game.profit < 0
                    ? 'text-red-400'
                    : 'text-white'
                }`}
              >
                {game.profit.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};

const HistoryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoading(true);
        const gameData = await gameService.getUserGames();
        const filteredGames = gameData.filter(
          (game) => game.status === 'completed' || game.status === 'displaying_transactions'
        );
        const sortedGames = [...filteredGames].sort(
          (a, b) => new Date(b.start_time) - new Date(a.start_time)
        );
        setGames(sortedGames);
      } catch (err) {
        console.error('Error fetching games:', err);
        setError('Failed to load game data.');
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  const tabs = [
    {
      path: '/history',
      icon: Award,
      label: 'Overview',
      component: <Overview games={games} userId={user?.id} />,
    },
    {
      path: '/history/games',
      icon: BarChart,
      label: 'Games',
      component: <GameHistory games={games} />,
    },
    {
      path: '/history/personal',
      icon: TrendingUp,
      label: 'Personal Stats',
      component: <PersonalStats games={games} />,
    },
  ];

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center h-screen text-white"
      >
        <div className="flex flex-col items-center gap-4">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            <AlertCircle className="w-8 h-8 text-yellow-500 animate-spin" />
          </motion.div>
          <p className="text-yellow-500">Loading...</p>
        </div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex items-center justify-center h-screen text-red-500"
      >
        <AlertCircle className="w-6 h-6 mr-2" />
        <span>{error}</span>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-poker-green-dark"
    >
      {/* Navigation Tabs */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
        className="border-b border-green-800"
      >
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-wrap">
            {tabs.map((tab) => (
              <motion.button
                key={tab.path}
                onClick={() => navigate(tab.path)}
                className={`flex items-center px-4 sm:px-8 py-2 sm:py-4 font-bold text-sm transition-colors ${
                  location.pathname === tab.path
                    ? 'border-b-2 border-yellow-500 text-yellow-500'
                    : 'text-gray-400 hover:text-yellow-400'
                }`}
                whileHover={{ scale: 1.1 }}
              >
                <tab.icon className="w-5 h-5 mr-2" />
                {tab.label}
              </motion.button>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Content */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
        className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8"
      >
        <Routes>
          <Route path="/" element={<Overview games={games} userId={user?.id} />} />
          <Route path="/games" element={<GameHistory games={games} />} />
          <Route path="/personal" element={<PersonalStats games={games} />} />
        </Routes>
      </motion.div>
    </motion.div>
  );
};

export default HistoryPage;
