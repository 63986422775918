import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import logo from '../assets/logo.png';
import { motion } from 'framer-motion';

// Comprehensive Poker Glossary Data
const glossaryData = [
  {
    term: 'Action',
    definition: 'Any decision made during play (bet, call, raise, fold). Also refers to having an active hand.'
  },
  {
    term: 'Ace-High',
    definition: 'A hand with no pair where an Ace is the highest card. Sometimes called "ace-rag" when accompanied by low cards.'
  },
  {
    term: 'All-In',
    definition: 'When a player bets all their remaining chips.'
  },
  {
    term: 'Ante',
    definition: 'A mandatory bet that all players must make before the hand begins.'
  },
  {
    term: 'Air',
    definition: 'A hand with absolutely nothing – no pair, no draw.'
  },
  {
    term: 'Backdoor',
    definition: 'Drawing to a hand that requires two perfect cards on both the turn and river.'
  },
  {
    term: 'Bad Beat',
    definition: 'Losing a hand where you were statistically favored to win.'
  },
  {
    term: 'Bad Card',
    definition: 'A community card that helps your opponent more than you. Sometimes called a "scare card."'
  },
  {
    term: 'Big Blind',
    definition: 'A mandatory bet made by the player two seats to the left of the dealer button.'
  },
  {
    term: 'Big Slick',
    definition: 'Ace-King hole cards.'
  },
  {
    term: 'Bluff',
    definition: 'Betting or raising with a weak hand to make opponents fold better hands.'
  },
  {
    term: 'Board',
    definition: 'The community cards shared by all players in Hold\'em and Omaha.'
  },
  {
    term: 'Bullets',
    definition: 'A nickname for pocket Aces.'
  },
  {
    term: 'Button',
    definition: 'The dealer position, indicated by a dealer button, and considered the strongest position at the table.'
  },
  {
    term: 'Call',
    definition: 'Matching the current bet amount to stay in the hand.'
  },
  {
    term: 'Check',
    definition: 'Declining to bet while remaining in the hand. Only possible if no bets have been made.'
  },
  {
    term: 'Chip',
    definition: 'The currency used in poker games, representing different monetary values.'
  },
  {
    term: 'Cooler',
    definition: 'A very strong hand that loses to an even stronger hand, often through no fault of the player.'
  },
  {
    term: 'Cowboys',
    definition: 'A nickname for pocket Kings.'
  },
  {
    term: 'Cracking',
    definition: 'Beating a strong starting hand, such as "cracking Aces."'
  },
  {
    term: 'Dead Man\'s Hand',
    definition: 'A two pair holding, Aces and Eights, famously associated with Wild Bill Hickok.'
  },
  {
    term: 'Dinosaur',
    definition: 'An extremely tight/passive player who rarely plays hands.'
  },
  {
    term: 'Donk (Donkey)',
    definition: 'A weak player making poor decisions. Also refers to a "donk bet," which is leading into the aggressor from the previous street.'
  },
  {
    term: 'Draw',
    definition: 'A hand that needs additional cards to become strong, like a flush or straight draw.'
  },
  {
    term: 'Draw Dead',
    definition: 'A situation where, even if you complete your draw, you cannot win.'
  },
  {
    term: 'Duck',
    definition: 'A nickname for pocket deuces (2-2).'
  },
  {
    term: 'Fish',
    definition: 'A weak player who often loses and calls too frequently.'
  },
  {
    term: 'Flop',
    definition: 'The first three community cards dealt face-up in Hold\'em and Omaha.'
  },
  {
    term: 'Flush',
    definition: 'A hand with five cards of the same suit.'
  },
  {
    term: 'Fold',
    definition: 'Discarding your hand and forfeiting any chance at the pot.'
  },
  {
    term: 'Food',
    definition: 'Slang for an extremely weak player. "There\'s good food at the table" means there are easy targets to win money from.'
  },
  {
    term: 'Hand',
    definition: 'The combination of cards a player holds and/or the showdown comparison of all players\' final cards.'
  },
  {
    term: 'Heads-Up',
    definition: 'A situation or game with only two players remaining.'
  },
  {
    term: 'Hero Call',
    definition: 'Calling a bet with a marginal hand because you suspect your opponent is bluffing.'
  },
  {
    term: 'Hook',
    definition: 'A nickname for a Jack or sometimes King-Jack (KJ).'
  },
  {
    term: 'Horse',
    definition: 'A nickname for pocket Kings.'
  },
  {
    term: 'Jackpot',
    definition: 'A promotional prize, often awarded for specific rare hands.'
  },
  {
    term: 'Jam',
    definition: 'To go all-in.'
  },
  {
    term: 'Junk',
    definition: 'A very weak starting hand with little potential.'
  },
  {
    term: 'Kicker',
    definition: 'A high card used to break ties between similar hands.'
  },
  {
    term: 'Kill Pot',
    definition: 'A pot where the stakes are temporarily increased, usually after a player wins consecutive hands.'
  },
  {
    term: 'Ladies',
    definition: 'A nickname for pocket Queens.'
  },
  {
    term: 'Limping',
    definition: 'Entering a pot by just calling the big blind rather than raising.'
  },
  {
    term: 'Live Cards',
    definition: 'Unseen cards that can still improve your hand.'
  },
  {
    term: 'Live One',
    definition: 'A player who plays poorly but still has money to lose.'
  },
  {
    term: 'Monster',
    definition: 'An extremely strong hand, often unbeatable or nearly so.'
  },
  {
    term: 'Muck',
    definition: 'The pile of folded cards. To muck your hand is to fold without showing.'
  },
  {
    term: 'Murder Cards',
    definition: 'Community cards that severely diminish your chances of winning.'
  },
  {
    term: 'Nit',
    definition: 'A very tight player who plays very few hands and rarely bluffs.'
  },
  {
    term: 'Nuts',
    definition: 'The best possible hand given the current community cards.'
  },
  {
    term: 'Odds',
    definition: 'The probability of completing a hand, often used to determine the profitability of a call.'
  },
  {
    term: 'Old Man Coffee',
    definition: 'A stereotypically older, tight player who only plays premium hands.'
  },
  {
    term: 'Orbit',
    definition: 'A full rotation around the table, with each player posting the blinds once.'
  },
  {
    term: 'Out',
    definition: 'A card that, if dealt, will improve your hand to likely win the pot.'
  },
  {
    term: 'Overcard',
    definition: 'A card in your hand that is higher than any card on the board.'
  },
  {
    term: 'Paint',
    definition: 'Face cards: Jacks, Queens, and Kings.'
  },
  {
    term: 'Pocket Rockets',
    definition: 'Another nickname for pocket Aces (AA).'
  },
  {
    term: 'Position',
    definition: 'Your place at the table relative to the dealer button, affecting when you act in each betting round.'
  },
  {
    term: 'Pot',
    definition: 'The total amount of chips bet in the current hand.'
  },
  {
    term: 'Punt',
    definition: 'To make a reckless, losing play.'
  },
  {
    term: 'Quads',
    definition: 'Four-of-a-kind.'
  },
  {
    term: 'Rags',
    definition: 'Low, unconnected cards with minimal value.'
  },
  {
    term: 'Rainbow',
    definition: 'A flop or board with three (or more) suits represented, reducing flush possibilities.'
  },
  {
    term: 'Raise',
    definition: 'Increasing the size of the current bet.'
  },
  {
    term: 'Range',
    definition: 'The spectrum of hands a player might have based on their actions.'
  },
  {
    term: 'River Rat',
    definition: 'A player who consistently wins by hitting a lucky card on the river.'
  },
  {
    term: 'Run It Twice',
    definition: 'Dealing the remaining community cards twice and splitting the pot, often done to reduce variance.'
  },
  {
    term: 'Satellite',
    definition: 'A lower-stakes tournament where the prize is entry into a larger tournament.'
  },
  {
    term: 'Short Stack',
    definition: 'Having fewer chips than most of the players at the table.'
  },
  {
    term: 'Showdown',
    definition: 'When remaining players reveal their cards to determine the winner after the last betting round.'
  },
  {
    term: 'Small Blind',
    definition: 'A mandatory, smaller blind bet to the immediate left of the dealer button.'
  },
  {
    term: 'Snapcall',
    definition: 'Calling a bet instantly without hesitation.'
  },
  {
    term: 'Steam',
    definition: 'Playing poorly due to emotional distress. Similar to tilt.'
  },
  {
    term: 'Suited Connectors',
    definition: 'Hole cards of consecutive ranks and the same suit, like 8♥9♥.'
  },
  {
    term: 'Tank',
    definition: 'Taking a long time to make a decision.'
  },
  {
    term: 'Tell',
    definition: 'A physical or behavioral clue that may reveal the strength of a player’s hand.'
  },
  {
    term: 'Tilt',
    definition: 'Playing badly due to emotional frustration or anger.'
  },
  {
    term: 'Train',
    definition: 'Multiple players calling in quick succession.'
  },
  {
    term: 'Turn',
    definition: 'The fourth community card dealt in Hold\'em and Omaha, also known as "Fourth Street."'
  },
  {
    term: 'Under the Gun (UTG)',
    definition: 'The first player to act in a betting round.'
  },
  {
    term: 'Underdog',
    definition: 'A hand not favored to win when all cards are dealt.'
  },
  {
    term: 'Value Bet',
    definition: 'A bet made with a strong hand aiming to be called by worse hands.'
  },
  {
    term: 'Variance',
    definition: 'Statistical fluctuations in results over the short-term, even with correct play.'
  },
  {
    term: 'Villain',
    definition: 'A common term in hand histories referring to the opponent or opponents.'
  },
  {
    term: 'Whale',
    definition: 'A very wealthy but weak player who loses significant amounts of money.'
  },
  {
    term: 'Wheel',
    definition: 'A five-high straight (A-2-3-4-5).'
  },
  {
    term: 'Wired Pair',
    definition: 'Another term for a pocket pair.'
  }
];

function Glossary() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredGlossary = useMemo(() => {
    return glossaryData.filter(item =>
      item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.definition.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col"
    >
      {/* Logo Banner */}
      <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
        <motion.img 
          src={logo} 
          alt="SettleStack Logo" 
          className="h-32 w-auto"
          animate={{ rotateY: [0, 360] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatDelay: 5,
          }}
        />
      </header>
  
      <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
        <motion.h2
          className="text-3xl font-bold text-poker-gold mb-6 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Comprehensive Poker Glossary
        </motion.h2>
  
        {/* Search Bar */}
        <motion.div
          className="mb-8 flex justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.input
            type="text"
            placeholder="Search terms..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-1/2 p-3 text-black rounded-md focus:outline-none"
            aria-label="Search glossary terms"
            whileFocus={{ scale: 1.05 }}
          />
        </motion.div>
  
        {/* Glossary Terms */}
        <div className="grid grid-cols-1 gap-4">
          {filteredGlossary.map((item, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: idx * 0.1 }}
            >
              <Card className="bg-card-bg border-poker-gold backdrop-blur-sm hover:shadow-lg transition">
                <CardContent className="p-6">
                  <h3 className="text-2xl font-semibold text-poker-gold mb-2">
                    {item.term}
                  </h3>
                  <p className="text-yellow-200 text-sm leading-relaxed">
                    {item.definition}
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
  
        {/* Back Button */}
        <motion.div
          className="mt-8 flex justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <Button
            className="bg-poker-gold text-poker-dark hover:bg-yellow-400"
            onClick={() => navigate('/resources')}
            aria-label="Back to Resources"
          >
            ← Back to Resources
          </Button>
        </motion.div>
      </main>
  
      {/* Footer */}
      <footer className="bg-poker-dark p-6">
        <div className="max-w-7xl mx-auto text-center text-poker-gold">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </motion.div>
  );
}

export default Glossary;
