import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Alert, AlertDescription } from '../ui/alert';
import { ChevronLeft, BarChart2, TrendingUp, Users, DollarSign } from 'lucide-react';
import { gameService } from '../../services/game.service';
import { formatCurrency, formatDate } from '../../utils/stats';
import { 
  LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer 
} from 'recharts';
import { motion } from 'framer-motion';

const SessionAnalysis = () => {
  const { gameId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [games, setGames] = useState([]);
  const [analysis, setAnalysis] = useState(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setLoading(true);
        let gamesData = [];

        if (gameId) {
          // Single game analysis
          const game = await gameService.getGameById(gameId);
          gamesData = [game];
        } else {
          // Multiple games analysis
          const gameIds = searchParams.get('games')?.split(',') || [];
          console.log('Game IDs from URL:', gameIds);
          
          const fetchedGames = await Promise.all(
            gameIds.map(id => gameService.getGameById(id))
          );
          console.log('Fetched games:', fetchedGames);
          gamesData = fetchedGames;
        }

        setGames(gamesData);
        calculateAnalysis(gamesData);
      } catch (err) {
        console.error('Error fetching games:', err);
        setError('Failed to fetch game data');
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [gameId, searchParams]);

  const calculateAnalysis = (gamesData) => {
    const analysis = {
      totalGames: gamesData.length,
      totalPlayers: new Set(gamesData.flatMap(game => 
        game.players.map(player => player.user_id)
      )).size,
      totalBuyIn: 0,
      totalPot: 0,
      dateRange: {
        start: null,
        end: null
      },
      playerStats: {},
      biggestPot: {
        amount: 0,
        gameId: null,
        date: null
      },
      averagePlayTime: 0,
      transactions: []
    };

    gamesData.forEach(game => {
      // Calculate total buy-ins and pot
      const gameBuyIn = game.players.reduce((sum, player) => 
        sum + parseFloat(player.buy_in || 0), 0);
      analysis.totalBuyIn += gameBuyIn;

      if (gameBuyIn > analysis.biggestPot.amount) {
        analysis.biggestPot = {
          amount: gameBuyIn,
          gameId: game.id,
          date: game.created_at
        };
      }

      // Track player performance
      game.players.forEach(player => {
        if (!analysis.playerStats[player.user_id]) {
          analysis.playerStats[player.user_id] = {
            name: player.name,
            gamesPlayed: 0,
            totalBuyIn: 0,
            totalNet: 0,
            biggestWin: 0,
            biggestLoss: 0
          };
        }

        const stats = analysis.playerStats[player.user_id];
        stats.gamesPlayed++;
        stats.totalBuyIn += parseFloat(player.buy_in || 0);
        
        const net = parseFloat(player.final_balance || 0) - parseFloat(player.buy_in || 0);
        stats.totalNet += net;
        
        if (net > stats.biggestWin) stats.biggestWin = net;
        if (net < stats.biggestLoss) stats.biggestLoss = net;
      });

      // Collect all transactions
      if (game.transactions) {
        analysis.transactions = [
          ...analysis.transactions,
          ...game.transactions.map(t => ({ ...t, gameId: game.id }))
        ];
      }
    });

    // Sort and set date range
    const dates = gamesData.map(game => new Date(game.created_at));
    analysis.dateRange = {
      start: formatDate(Math.min(...dates)),
      end: formatDate(Math.max(...dates))
    };

    setAnalysis(analysis);
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center h-64"
      >
        <motion.div
          className="animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500"
          transition={{ repeat: Infinity, duration: 1 }}
        ></motion.div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex items-center justify-center h-64"
      >
        <Alert variant="destructive" className="border-2 border-red-500 bg-red-900 p-4 rounded-lg">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </motion.div>
    );
  }

  const prepareChartData = (gamesData) => {
    const playerRunningTotals = {};
    const sortedGames = [...gamesData].sort((a, b) => 
      new Date(a.created_at) - new Date(b.created_at)
    );
  
    // Line chart data - show running totals over time
    const lineData = sortedGames.map(game => {
      const dataPoint = {
        date: formatDate(game.created_at)
      };
  
      game.players.forEach(player => {
        // Initialize running total for this player if needed
        if (!playerRunningTotals[player.name]) {
          playerRunningTotals[player.name] = 0;
        }
  
        // Calculate profit and add to running total
        const profit = parseFloat(player.final_balance || 0) - parseFloat(player.buy_in || 0);
        playerRunningTotals[player.name] += profit;
        dataPoint[player.name] = playerRunningTotals[player.name];
      });
  
      return dataPoint;
    });
  
    // Bar chart data - show total profits
    const barData = Object.entries(playerRunningTotals).map(([name, total]) => ({
      player: name,
      profit: total
    }));
  
    return { 
      lineData,
      barData,
      playerNames: Object.keys(playerRunningTotals)
    };
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6 px-4 py-6"
    >
      {/* Header Section */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col md:flex-row items-center justify-between gap-4"
      >
        <Button
          onClick={() => navigate('/history')}
          variant="outline"
          className="text-yellow-500 border-yellow-500 hover:bg-green-800 flex items-center"
        >
          <ChevronLeft className="w-4 h-4 mr-2" />
          Back to History
        </Button>
        <h2 className="text-2xl font-bold text-yellow-500 text-center md:text-left">
          Session Analysis {games.length > 1 ? `(${games.length} Games)` : ''}
        </h2>
      </motion.div>

      {analysis && (
        <>
          {/* Overview Cards */}
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
            }}
          >
            {/* Total Players Card */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 flex items-center gap-2 text-lg">
                    <Users className="w-5 h-5" />
                    Total Players
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold text-white">{analysis.totalPlayers}</div>
                </CardContent>
              </Card>
            </motion.div>

            {/* Total Buy-in Card */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 flex items-center gap-2 text-lg">
                    <DollarSign className="w-5 h-5" />
                    Total Buy-in
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold text-white">{formatCurrency(analysis.totalBuyIn)}</div>
                </CardContent>
              </Card>
            </motion.div>

            {/* Biggest Pot Card */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 flex items-center gap-2 text-lg">
                    <BarChart2 className="w-5 h-5" />
                    Biggest Pot
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold text-white">{formatCurrency(analysis.biggestPot.amount)}</div>
                  <div className="text-sm text-yellow-500">{formatDate(analysis.biggestPot.date)}</div>
                </CardContent>
              </Card>
            </motion.div>

            {/* Date Range Card */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 flex items-center gap-2 text-lg">
                    <TrendingUp className="w-5 h-5" />
                    Date Range
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-sm text-white">
                    {analysis.dateRange.start} - {analysis.dateRange.end}
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          {/* Charts Section */}
          <motion.div
            className="space-y-6"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
            }}
          >
            {/* Player Profits Over Time */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 text-lg">Player Profits Over Time</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="h-64 sm:h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={prepareChartData(games).lineData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#ffffff22" />
                        <XAxis 
                          dataKey="date" 
                          stroke="#eab308" 
                          tick={{ fill: '#eab308' }}
                          angle={-45}
                          textAnchor="end"
                          height={60}
                        />
                        <YAxis 
                          stroke="#eab308" 
                          tick={{ fill: '#eab308' }}
                          tickFormatter={value => formatCurrency(value)}
                        />
                        <Tooltip 
                          contentStyle={{ 
                            backgroundColor: '#064e3b', 
                            borderColor: '#eab308',
                            color: '#eab308' 
                          }}
                          formatter={value => formatCurrency(value)}
                        />
                        <Legend 
                          verticalAlign="top" 
                          height={36}
                          wrapperStyle={{ color: '#eab308' }}
                        />
                        {prepareChartData(games).playerNames.map((player, index) => (
                          <Line 
                            key={player}
                            type="monotone" 
                            dataKey={player}
                            stroke={`hsl(${index * 60}, 70%, 50%)`}
                            strokeWidth={2}
                            dot={{ fill: `hsl(${index * 60}, 70%, 50%)`, strokeWidth: 2 }}
                          />
                        ))}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            </motion.div>

            {/* Total Profit/Loss by Player */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 text-lg">Total Profit/Loss by Player</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="h-64 sm:h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={prepareChartData(games).barData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#ffffff22" />
                        <XAxis 
                          dataKey="player" 
                          stroke="#eab308" 
                          tick={{ fill: '#eab308' }}
                          angle={-45}
                          textAnchor="end"
                          height={60}
                        />
                        <YAxis 
                          stroke="#eab308" 
                          tick={{ fill: '#eab308' }}
                          tickFormatter={value => formatCurrency(value)}
                        />
                        <Tooltip 
                          contentStyle={{ 
                            backgroundColor: '#064e3b', 
                            borderColor: '#eab308',
                            color: '#eab308' 
                          }}
                          formatter={value => formatCurrency(value)}
                        />
                        <Bar 
                          dataKey="profit" 
                          fill="#eab308"
                          animationDuration={1500}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          {/* Player Performance */}
          <motion.div
            className="space-y-4"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
            }}
          >
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 text-lg">Player Performance</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Object.values(analysis.playerStats).map((player) => (
                      <motion.div
                        key={player.name}
                        whileHover={{ scale: 1.05 }}
                        className="border border-yellow-500/50 bg-green-800 p-4 rounded-lg"
                      >
                        <CardHeader>
                          <CardTitle className="text-lg text-yellow-500">{player.name}</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <div className="space-y-2">
                            <div className="flex justify-between">
                              <span className="text-yellow-500">Games Played:</span>
                              <span className="text-white">{player.gamesPlayed}</span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-yellow-500">Total Buy-in:</span>
                              <span className="text-white">{formatCurrency(player.totalBuyIn)}</span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-yellow-500">Net Profit/Loss:</span>
                              <span className={player.totalNet >= 0 ? 'text-green-400' : 'text-red-400'}>
                                {formatCurrency(player.totalNet)}
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-yellow-500">Biggest Win:</span>
                              <span className="text-green-400">{formatCurrency(player.biggestWin)}</span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-yellow-500">Biggest Loss:</span>
                              <span className="text-red-400">{formatCurrency(player.biggestLoss)}</span>
                            </div>
                          </div>
                        </CardContent>
                      </motion.div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          {/* Transactions List */}
          {analysis.transactions.length > 0 && (
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5 }}
            >
              <Card className="border-2 border-yellow-500 bg-green-900 p-4 rounded-lg">
                <CardHeader>
                  <CardTitle className="text-yellow-500 text-lg">All Transactions</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-2 max-h-64 overflow-y-auto">
                    {analysis.transactions.map((transaction, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.05 }}
                        className="flex justify-between items-center border-b border-yellow-500/20 py-2"
                      >
                        <span className="text-white">{transaction.description}</span>
                        <span className="text-yellow-500">{formatCurrency(transaction.amount)}</span>
                      </motion.div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default SessionAnalysis;
