import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import logo from '../assets/logo.png';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext'; // Import Auth Context

function Resources() {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get user authentication status

  const resources = [
    {
      title: 'Glossary of Poker Terms',
      description: 'Confused by poker lingo? Explore a comprehensive glossary that defines common terms, slang, and jargon used on the felt.',
      route: '/resources/glossary',
    },
    {
      title: 'Hand Rankings',
      description: 'Not sure which hands outrank others? Reference this visual guide to quickly determine the strength of your hand.',
      route: '/resources/hand-rankings',
    },
    {
      title: 'Player Types',
      description: 'Learn how to identify and adapt to different poker player archetypes. Become a chameleon at the table for a winning edge.',
      route: '/resources/player-types',
    },
    {
      title: 'Common Mistakes',
      description: 'Avoid pitfalls and errors that cost chips. Understand the most frequent mistakes players make and learn how to dodge them.',
      route: '/resources/common-mistakes',
    },
    {
      title: 'Equity & Odds',
      description: 'Master the math behind the game. Understand probabilities, pot odds, and expected value to play optimally.',
      route: '/resources/equity-and-odds',
    },
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col"
    >
      {/* Back to Home/Dashboard Button */}
      <div className="absolute top-6 left-6">
        <Button
          className="bg-poker-gold text-poker-dark hover:bg-yellow-400"
          onClick={() => navigate(user ? '/dashboard' : '/')}
        >
          ← {user ? 'Back to Dashboard' : 'Back to Home'}
        </Button>
      </div>

      {/* Logo Banner */}
      <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
        <motion.img 
          src={logo} 
          alt="SettleStack Logo" 
          className="h-32 w-auto"
          animate={{ rotateY: [0, 360] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatDelay: 5,
          }}
        />
      </header>

      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
        <motion.h2
          className="text-3xl font-bold text-poker-gold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Resources to Enhance Your Poker Game
        </motion.h2>
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {resources.map((resource, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: idx * 0.2 }}
            >
              <Card
                className="bg-card-bg border-poker-gold backdrop-blur-sm hover:shadow-lg transition cursor-pointer"
                onClick={() => navigate(resource.route)}
              >
                <CardContent className="p-6 flex flex-col justify-between h-full">
                  <h3 className="text-2xl font-semibold text-poker-gold mb-3">
                    {resource.title}
                  </h3>
                  <p className="text-yellow-200 text-sm leading-relaxed mb-4">
                    {resource.description}
                  </p>
                  <Button
                    className="mt-auto bg-poker-gold text-poker-dark hover:bg-yellow-400"
                    aria-label={`Learn more about ${resource.title}`}
                  >
                    Learn More »
                  </Button>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-poker-dark p-6">
        <div className="max-w-7xl mx-auto text-center text-poker-gold">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </motion.div>
  );
}

export default Resources;
