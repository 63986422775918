import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../../assets/logo.png';

const PokerLoadingTransition = ({ 
  isVisible, 
  onComplete = () => {},
}) => {
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const audioRef = useRef(new Audio('/sounds/settlestackintro.mp3'));

  // Play sound when component becomes visible
  useEffect(() => {
    if (isVisible) {
      // Reset audio to start
      audioRef.current.currentTime = 0;
      
      // Play audio
      const playPromise = audioRef.current.play();
      
      // Handle play promise rejection
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log('Audio playback failed:', error);
        });
      }
    }
    
    // Cleanup
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, [isVisible]);

  // Progress handling - adjusted to take longer
  useEffect(() => {
    if (!isVisible) {
      setProgress(0);
      setIsComplete(false);
      return;
    }

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          setIsComplete(true);
          return 100;
        }
        return prev + 0.7; // Reduced increment rate to extend duration
      });
    }, 50);

    return () => clearInterval(progressInterval);
  }, [isVisible]);

  // Handle completion with fade out
  useEffect(() => {
    if (isComplete) {
      const timer = setTimeout(() => {
        onComplete();
      }, 1000); // Wait for fade out animation
      return () => clearTimeout(timer);
    }
  }, [isComplete, onComplete]);

  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 bg-black z-50 overflow-hidden"
        >
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            {/* Welcome Text */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ 
                delay: 0.5, 
                duration: 1,
                ease: "easeOut"
              }}
              className="text-4xl md:text-5xl text-white font-bold tracking-wider mb-12"
            >
              Welcome to
            </motion.div>

            {/* Logo Container */}
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ 
                opacity: 1, 
                scale: 1,
                rotate: [0, 360],
              }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                opacity: { delay: 1, duration: 0.5 },
                scale: { delay: 1, duration: 1.5, ease: "easeOut" },
                rotate: { delay: 1, duration: 1.5, ease: "easeOut" }
              }}
              className="relative w-48 h-48 md:w-64 md:h-64 mb-16"
            >
              {/* Glowing effect */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 0.5, 0] }}
                transition={{
                  delay: 1.5,
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="absolute inset-0 rounded-full bg-white/20 blur-xl"
              />
              
              <img 
                src={logo} 
                alt="Logo"
                className="w-full h-full object-contain relative z-10"
              />
            </motion.div>

            {/* Progress bar section */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 2 }}
              className="text-center"
            >
              {/* Loading text */}
              <motion.div
                animate={{ opacity: [0.5, 1] }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="text-yellow-400 text-xl mb-4"
              >
                Loading...
              </motion.div>

              {/* Progress bar */}
              <div className="w-80 h-3 bg-black/50 rounded-full overflow-hidden border border-yellow-500/50">
                <motion.div
                  className="h-full bg-gradient-to-r from-yellow-500 to-yellow-300"
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  transition={{ duration: 0.1 }}
                />
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PokerLoadingTransition;