import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axios'; // Import axiosInstance

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(null);

  const logout = async () => {
    localStorage.removeItem('accessToken'); 
    localStorage.removeItem('user');
    delete axiosInstance.defaults.headers.common['Authorization'];
    setUser(null);
    setToken(null);
  };

  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem('accessToken'); // Consistent key
      const storedUser = JSON.parse(localStorage.getItem('user')); // Ensure user is stored as JSON

      if (storedToken && storedUser) {
        try {
          // Verify token with backend
          const response = await axiosInstance.get('/api/auth/verify', {
            headers: { Authorization: `Bearer ${storedToken}` },
          });
          setUser(response.data.user); // Adjust based on your verify response
          setToken(storedToken);
        } catch (err) {
          console.error('Auth verification failed:', err);
          logout();
        }
      }

      setLoading(false);
    };
    initializeAuth();
  }, []);

  const login = async (email, password) => {
    try {
        setError(null); // Reset errors

        // Use axiosInstance with the correct API base URL
        const response = await axiosInstance.post('/api/auth/login', {
            email,
            password,
        });

        const { accessToken, user: userData } = response.data;

        // Store token and user data
        localStorage.setItem('accessToken', accessToken); // Updated to match accessToken key
        localStorage.setItem('user', JSON.stringify(userData));

        // Set auth header for future requests
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        setUser(userData); // Update user state
        setToken(accessToken); // Update token state
        return userData; // Return user data
    } catch (err) {
        setError(err.response?.data?.message || 'Failed to login');
        throw err; // Propagate error
    }
};

  const register = async (email, password, username, venmoHandle) => {
    try {
      setError(null);
      const response = await axiosInstance.post('/api/auth/register', {
        username,
        email,
        password,
        venmo_handle: venmoHandle,
      });
      return response.data;
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to register');
      throw err;
    }
  };

  const updateUser = (updatedUserData) => {
    try {
      setError(null);
      setUser(updatedUserData);
      localStorage.setItem('user', JSON.stringify(updatedUserData));
    } catch (err) {
      setError('Failed to update user data locally');
      throw err;
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axiosInstance.post('/auth/refresh-token', {}, { 
        withCredentials: true 
      });
      const { accessToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return accessToken;
    } catch (err) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      window.location.href = '/login';  // Force redirect to login
      throw err;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentToken = localStorage.getItem('accessToken'); // Use 'accessToken'
      if (currentToken) {
        try {
          const { exp } = JSON.parse(atob(currentToken.split('.')[1]));
          if (Date.now() >= exp * 1000 - (1000 * 60 * 60)) { // Refresh 1 hour before expiry
            refreshToken();
          }
        } catch (err) {
          console.error('Error parsing token:', err);
        }
      }
    }, 30 * 60 * 1000); // Check every 30 minutes

    setRefreshInterval(interval);
    return () => clearInterval(interval);
  }, []);

  const value = {
    user,
    token,
    loading,
    error,
    login,
    register,
    logout,
    updateUser,
    isAuthenticated: !!user,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Render children only after loading */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
