// utils/stats.js

// Format currency with proper handling of null/undefined values
export const formatCurrency = (amount) => {
  if (amount === null || amount === undefined || isNaN(amount)) return "$0.00";
  const number = parseFloat(amount);
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

// Format date with proper handling of invalid dates
export const formatDate = (date) => {
  if (!date) return "N/A";
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return "Invalid Date";
  
  return parsedDate.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
};

export const calculateStandardDeviation = (values, mean) => {
  if (values.length === 0) return 0;
  const variance = values.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / values.length;
  return Math.sqrt(variance);
};

// Calculate win rate as a percentage
export const calculateWinRate = (wins, total) => {
  if (!total) return "0%";
  return `${((wins / total) * 100).toFixed(1)}%`;
};

// Main statistics calculation function
export const calculateStatistics = (games, userId) => {
  // Initialize stats object with default values
  const stats = {
    totalGames: 0,
    totalCompletedGames: 0,
    netProfit: 0,
    biggestWin: 0,
    biggestLoss: 0,
    bestGameDate: null,
    worstGameDate: null,
    highestWinStreak: 0,
    currentWinStreak: 0,
    highestLossStreak: 0,
    currentLossStreak: 0,
    avgBuyIn: 0,
    avgProfit: 0,
    totalBuyins: 0,
    winRate: "0%",
    totalWins: 0,
    totalLosses: 0,
    totalBreakeven: 0,
    recentGames: [],
    profitByMonth: {},
    bestMonth: null,
    worstMonth: null
  };

  if (!Array.isArray(games) || games.length === 0 || !userId) {
    return stats;
  }

  // Filter user's games
  const userGames = games.filter(game => 
    game.players.some(player => player.user_id === userId)
  );

  stats.totalGames = userGames.length;

  // Filter completed games
  const completedGames = userGames.filter(game => 
    game.status === 'completed' || game.status === 'displaying_transactions'
  ).sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  stats.totalCompletedGames = completedGames.length;

  // Track running totals
  let totalBuyIn = 0;
  let totalProfit = 0;
  let winStreak = 0;
  let lossStreak = 0;
  let lastResult = null;

  // Process each completed game
  completedGames.forEach(game => {
    const player = game.players.find(p => p.user_id === userId);
    if (!player) return;

    const buyIn = parseFloat(player.buy_in || 0);
    const finalBalance = parseFloat(player.final_balance || 0);
    const profit = finalBalance - buyIn;
    const gameDate = new Date(game.created_at || game.start_time);
    const monthKey = `${gameDate.getFullYear()}-${(gameDate.getMonth() + 1).toString().padStart(2, '0')}`;

    // Update totals
    totalBuyIn += buyIn;
    totalProfit += profit;
    stats.netProfit += profit;
    stats.totalBuyins += buyIn;

    // Track monthly profits
    if (!stats.profitByMonth[monthKey]) {
      stats.profitByMonth[monthKey] = 0;
    }
    stats.profitByMonth[monthKey] += profit;

    // Update biggest win/loss
    if (profit > stats.biggestWin) {
      stats.biggestWin = profit;
      stats.bestGameDate = game.created_at || game.start_time;
    }
    if (profit < stats.biggestLoss) {
      stats.biggestLoss = profit;
      stats.worstGameDate = game.created_at || game.start_time;
    }

    // Track win/loss counts
    if (profit > 0) {
      stats.totalWins++;
      winStreak++;
      lossStreak = 0;
      if (winStreak > stats.highestWinStreak) {
        stats.highestWinStreak = winStreak;
      }
    } else if (profit < 0) {
      stats.totalLosses++;
      lossStreak++;
      winStreak = 0;
      if (lossStreak > stats.highestLossStreak) {
        stats.highestLossStreak = lossStreak;
      }
    } else {
      stats.totalBreakeven++;
      winStreak = 0;
      lossStreak = 0;
    }

    // Track current streaks
    stats.currentWinStreak = winStreak;
    stats.currentLossStreak = lossStreak;
  });

  // Calculate averages and rates
  if (stats.totalCompletedGames > 0) {
    stats.avgBuyIn = totalBuyIn / stats.totalCompletedGames;
    stats.avgProfit = totalProfit / stats.totalCompletedGames;
    stats.winRate = calculateWinRate(stats.totalWins, stats.totalCompletedGames);
  }

  // Calculate best and worst months
  const monthEntries = Object.entries(stats.profitByMonth);
  if (monthEntries.length > 0) {
    const [bestMonth] = monthEntries.reduce((best, current) => 
      current[1] > best[1] ? current : best
    );
    const [worstMonth] = monthEntries.reduce((worst, current) => 
      current[1] < worst[1] ? current : worst
    );
    
    stats.bestMonth = {
      month: bestMonth,
      profit: stats.profitByMonth[bestMonth]
    };
    stats.worstMonth = {
      month: worstMonth,
      profit: stats.profitByMonth[worstMonth]
    };
  }

  // Get recent games (last 5)
  stats.recentGames = completedGames.slice(-5).reverse().map(game => {
    const player = game.players.find(p => p.user_id === userId);
    return {
      date: game.created_at || game.start_time,
      profit: player ? parseFloat(player.final_balance) - parseFloat(player.buy_in) : 0,
      buyIn: player ? parseFloat(player.buy_in) : 0
    };
  });

  // Format currency values for display
  return {
    ...stats,
    netProfit: formatCurrency(stats.netProfit),
    biggestWin: formatCurrency(stats.biggestWin),
    biggestLoss: formatCurrency(stats.biggestLoss),
    avgBuyIn: formatCurrency(stats.avgBuyIn),
    avgProfit: formatCurrency(stats.avgProfit),
    totalBuyins: formatCurrency(stats.totalBuyins),
    bestGameDate: formatDate(stats.bestGameDate),
    worstGameDate: formatDate(stats.worstGameDate),
    recentGames: stats.recentGames.map(game => ({
      ...game,
      profit: formatCurrency(game.profit),
      buyIn: formatCurrency(game.buyIn),
      date: formatDate(game.date)
    }))
  };
};

// Calculate player-specific statistics
export const calculatePlayerStats = (games, userId) => {
  // Initialize stats object with default values
  const stats = {
    name: '',
    gamesPlayed: 0,
    winLossRecord: '0W - 0L',
    netProfit: formatCurrency(0),
    avgProfit: formatCurrency(0),
    bestGame: {
      date: 'N/A',
      profit: formatCurrency(0)
    },
    worstGame: {
      date: 'N/A',
      profit: formatCurrency(0)
    },
    opponents: {},
    bestOpponent: {
      name: 'N/A',
      profit: formatCurrency(0)
    },
    worstOpponent: {
      name: 'N/A',
      profit: formatCurrency(0)
    },
    profitByMonth: {},
    winRate: '0%',
    favoriteOpponents: [],
    recentGames: []
  };

  if (!games?.length || !userId) {
    return stats;
  }

  // Filter and process completed games
  const playerGames = games.filter(game => 
    (game.status === 'completed' || game.status === 'displaying_transactions') &&
    game.players.some(p => p.user_id === userId)
  );

  if (!playerGames.length) {
    // Return default stats if the user hasn't played any games
    return stats;
  }

  let totalProfit = 0;
  let wins = 0;
  let losses = 0;

  playerGames.forEach(game => {
    const player = game.players.find(p => p.user_id === userId);
    if (!player) return;

    const profit = parseFloat(player.final_balance || 0) - parseFloat(player.buy_in || 0);
    const gameDate = new Date(game.created_at || game.start_time);
    const monthKey = `${gameDate.getFullYear()}-${(gameDate.getMonth() + 1).toString().padStart(2, '0')}`;

    // Update basic stats
    stats.name = player.name;
    stats.gamesPlayed++;
    totalProfit += profit;

    // Track profit by month
    if (!stats.profitByMonth[monthKey]) {
      stats.profitByMonth[monthKey] = 0;
    }
    stats.profitByMonth[monthKey] += profit;

    // Track best/worst games
    // Track best/worst games
    if (profit > parseFloat(stats.bestGame.profit.replace(/[^0-9.-]+/g, ""))) {
      stats.bestGame = {
        date: game.created_at || game.start_time,
        profit: formatCurrency(profit) // Always format as currency string
      };
    }
    if (profit < parseFloat(stats.worstGame.profit.replace(/[^0-9.-]+/g, ""))) {
      stats.worstGame = {
        date: game.created_at || game.start_time,
        profit: formatCurrency(profit) // Always format as currency string
      };
    }


    // Track wins/losses
    if (profit > 0) wins++;
    else if (profit < 0) losses++;

    // Track opponent statistics
    game.players.forEach(opponent => {
      if (opponent.user_id === userId) return;

      if (!stats.opponents[opponent.user_id]) {
        stats.opponents[opponent.user_id] = {
          name: opponent.name,
          gamesPlayed: 0,
          totalProfit: 0
        };
      }

      stats.opponents[opponent.user_id].gamesPlayed++;
      stats.opponents[opponent.user_id].totalProfit += profit;
    });
  });

  // Calculate derived statistics
  stats.netProfit = totalProfit;
  stats.avgProfit = stats.gamesPlayed ? totalProfit / stats.gamesPlayed : 0;
  stats.winLossRecord = `${wins}W - ${losses}L`;
  stats.winRate = calculateWinRate(wins, stats.gamesPlayed);

  // Find best and worst opponents
  const opponents = Object.values(stats.opponents);
  if (opponents.length > 0) {
    const bestOpp = opponents.reduce((best, current) => 
      current.totalProfit > best.totalProfit ? current : best
    );
    const worstOpp = opponents.reduce((worst, current) => 
      current.totalProfit < worst.totalProfit ? current : worst
    );

    stats.bestOpponent = {
      name: bestOpp.name,
      profit: bestOpp.totalProfit
    };
    stats.worstOpponent = {
      name: worstOpp.name,
      profit: worstOpp.totalProfit
    };
  }

  // Get recent games
  stats.recentGames = playerGames.slice(-5).reverse().map(game => {
    const player = game.players.find(p => p.user_id === userId);
    return {
      date: game.created_at || game.start_time,
      profit: player ? parseFloat(player.final_balance) - parseFloat(player.buy_in) : 0,
      opponents: game.players.filter(p => p.user_id !== userId).map(p => p.name)
    };
  });

  return {
    ...stats,
    netProfit: formatCurrency(stats.netProfit),
    avgProfit: formatCurrency(stats.avgProfit),
    bestGame: {
      ...stats.bestGame,
      profit: formatCurrency(stats.bestGame.profit),
      date: formatDate(stats.bestGame.date)
    },
    worstGame: {
      ...stats.worstGame,
      profit: formatCurrency(stats.worstGame.profit),
      date: formatDate(stats.worstGame.date)
    },
    bestOpponent: {
      ...stats.bestOpponent,
      profit: formatCurrency(stats.bestOpponent.profit)
    },
    worstOpponent: {
      ...stats.worstOpponent,
      profit: formatCurrency(stats.worstOpponent.profit)
    },
    recentGames: stats.recentGames.map(game => ({
      ...game,
      profit: formatCurrency(game.profit),
      date: formatDate(game.date)
    }))
  };
};

export const calculateTotalTimePlayed = (games, raw = false) => {
  // Initialize a map to store total time for each user
  const timePlayedByUser = {};

  // Return an empty object if no games are provided
  if (!Array.isArray(games) || games.length === 0) {
    return timePlayedByUser;
  }

  games.forEach((game) => {
    const { start_time, game_ended, players } = game;

    // Ensure both start_time and game_ended are valid
    if (!start_time || !game_ended) {
      console.warn(`Skipping game with invalid timestamps:`, game);
      return;
    }

    // Calculate the duration of the game in seconds
    const gameDuration = (new Date(game_ended) - new Date(start_time)) / 1000; // Convert milliseconds to seconds
    if (gameDuration <= 0) {
      console.warn(`Skipping game with invalid duration:`, game);
      return;
    }

    // Skip games without valid players
    if (!Array.isArray(players) || players.length === 0) {
      console.warn(`Skipping game without valid players:`, game);
      return;
    }

    // Distribute the game duration among all players
    players.forEach((player) => {
      const userId = player.user_id;

      // Initialize time for the user if not already tracked
      if (!timePlayedByUser[userId]) {
        timePlayedByUser[userId] = 0;
      }

      // Add the game duration to the user's total
      timePlayedByUser[userId] += gameDuration;
    });
  });

  // Convert seconds to human-readable format (hours:minutes:seconds)
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return [
      String(hours).padStart(2, '0'),
      String(minutes).padStart(2, '0'),
      String(remainingSeconds).padStart(2, '0'),
    ].join(':');
  };

  // Return either raw seconds or formatted results
  return Object.entries(timePlayedByUser).reduce((result, [userId, totalSeconds]) => {
    result[userId] = raw ? totalSeconds : formatDuration(totalSeconds);
    return result;
  }, {});
};




// Calculate global player statistics with advanced metrics
export const calculatePlayerGlobalStats = (games, userId, user) => {
  const stats = {
    gamesPlayed: 0,
    totalWins: 0,
    totalLosses: 0,
    netProfit: 0,
    bestGame: { profit: 0, date: "N/A" },
    worstGame: { profit: 0, date: "N/A" },
    winRate: "0%",
    consistencyScore: 0,
    volatilityScore: 0,
    nemesis: { name: "N/A", lossesTo: 0, netLoss: 0 },
    bestTarget: { name: "N/A", winsAgainst: 0, netProfit: 0 },
  };

  // Validate the presence of game participations
  if (!user?.gameParticipations?.length) {
    return stats;
  }

  const profitHistory = [];
  const opponentStats = {};

  // Process completed participations
  const completedParticipations = user.gameParticipations.filter(
    (participation) =>
      participation?.profit_loss !== null &&
      participation?.game?.status === "completed"
  );

  completedParticipations.forEach((participation) => {
    const profit = parseFloat(participation.profit_loss);
    stats.gamesPlayed++;

    // Update net profit
    stats.netProfit += profit;

    // Track wins/losses
    if (profit > 0) {
      stats.totalWins++;
    } else if (profit < 0) {
      stats.totalLosses++;
    }

    // Track profit history
    profitHistory.push(profit);

    // Update best and worst games
    if (profit > stats.bestGame.profit) {
      stats.bestGame = {
        profit,
        date: participation.game?.created_at || "N/A",
      };
    }
    if (profit < stats.worstGame.profit) {
      stats.worstGame = {
        profit,
        date: participation.game?.created_at || "N/A",
      };
    }

    // Process opponents for nemesis/best target stats
    const game = games.find((g) => g.id === participation.game?.id);
    if (game) {
      game.players.forEach((opponent) => {
        if (opponent.user_id === userId) return;

        if (!opponentStats[opponent.user_id]) {
          opponentStats[opponent.user_id] = {
            name: opponent.name,
            gamesAgainst: 0,
            winsAgainst: 0,
            lossesTo: 0,
            netProfit: 0,
          };
        }

        const oppStats = opponentStats[opponent.user_id];
        oppStats.gamesAgainst++;
        if (profit > 0) oppStats.winsAgainst++;
        if (profit < 0) oppStats.lossesTo++;
        oppStats.netProfit += profit;
      });
    }
  });

  // Calculate win rate
  stats.winRate = stats.gamesPlayed
    ? ((stats.totalWins / stats.gamesPlayed) * 100).toFixed(1) + "%"
    : "0%";

  // Calculate consistency (standard deviation) and volatility (max swing)
  const avgProfit = stats.netProfit / stats.gamesPlayed || 0;
  stats.consistencyScore = Math.sqrt(
    profitHistory.reduce(
      (sum, val) => sum + Math.pow(val - avgProfit, 2),
      0
    ) / stats.gamesPlayed
  ).toFixed(2);
  stats.volatilityScore = Math.max(
    ...profitHistory.map((profit, i) =>
      i > 0 ? Math.abs(profit - profitHistory[i - 1]) : 0
    )
  ).toFixed(2);

  // Determine nemesis and best target
  Object.entries(opponentStats).forEach(([id, oppStats]) => {
    if (
      oppStats.lossesTo > stats.nemesis.lossesTo ||
      (oppStats.lossesTo === stats.nemesis.lossesTo &&
        oppStats.netProfit < stats.nemesis.netLoss)
    ) {
      stats.nemesis = {
        name: oppStats.name,
        lossesTo: oppStats.lossesTo,
        netLoss: oppStats.netProfit.toFixed(2),
      };
    }

    if (
      oppStats.winsAgainst > stats.bestTarget.winsAgainst ||
      (oppStats.winsAgainst === stats.bestTarget.winsAgainst &&
        oppStats.netProfit > stats.bestTarget.netProfit)
    ) {
      stats.bestTarget = {
        name: oppStats.name,
        winsAgainst: oppStats.winsAgainst,
        netProfit: oppStats.netProfit.toFixed(2),
      };
    }
  });

  // Format for display
  return {
    ...stats,
    netProfit: `$${stats.netProfit.toFixed(2)}`,
    bestGame: {
      profit: `$${stats.bestGame.profit.toFixed(2)}`,
      date: stats.bestGame.date,
    },
    worstGame: {
      profit: `$${stats.worstGame.profit.toFixed(2)}`,
      date: stats.worstGame.date,
    },
    consistencyScore: `$${stats.consistencyScore}`,
    volatilityScore: `$${stats.volatilityScore}`,
  };
};
