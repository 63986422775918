import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import logo from '../assets/logo.png';

function PlayerTypes() {
  const navigate = useNavigate();

  const playerTypes = [
    {
      title: 'The Donkey (a.k.a. The Fish)',
      characteristics: [
        'Plays poorly, making beginner-level mistakes.',
        'Overvalues weak hands and chases unlikely draws.',
        'Unaware of position, odds, or proper betting strategy.',
      ],
      exploitation: [
        'Play straightforward and value-bet strong hands heavily.',
        'Don’t bluff too often; they tend to call with any pair or draw.',
        'Capitalize on their loose calls by extracting maximum value.'
      ],
    },
    {
      title: 'The Calling Station',
      characteristics: [
        'Rarely folds once they have chips in the pot.',
        'Extremely passive, prefers calling over betting or raising.',
        'Pays off strong hands but won’t push you off yours.'
      ],
      exploitation: [
        'Value-bet relentlessly with your good hands.',
        'Avoid bluffing; they won’t fold often.',
        'Watch them call down and deliver steady profits.'
      ],
    },
    {
      title: 'The Aggro Donk',
      characteristics: [
        'Overly aggressive without coherent strategy.',
        'Bets and raises erratically, often overplaying weak hands.',
        'Tends to build big pots they ultimately lose.'
      ],
      exploitation: [
        'Tighten your range and stay patient.',
        'Let them bluff into you; trap with strong holdings.',
        'Don’t fight fire with fire; remain calm and extract value.'
      ],
    },
    {
      title: 'The Nit',
      characteristics: [
        'Plays extremely tight, only premium hands.',
        'Rarely bluffs, avoids risks.',
        'Predictable and easy to read.'
      ],
      exploitation: [
        'Steal their blinds and antes frequently.',
        'If they show aggression, respect their strong holdings.',
        'Win numerous small pots uncontested.'
      ],
    },
    {
      title: 'The Maniac',
      characteristics: [
        'Hyper-aggressive, bets and raises with almost any hand.',
        'Creates big pots and relentless action.',
        'Volatile play often leads to large swings.'
      ],
      exploitation: [
        'Play tight and wait for a premium hand.',
        'Trap them; let them bet into your strong holdings.',
        'Maintain discipline and avoid marginal confrontations.'
      ],
    },
    {
      title: 'The Shark',
      characteristics: [
        'Skilled, adaptive, and strategic.',
        'Comfortable playing GTO (Game Theory Optimal) when needed.',
        'Exploits opponents’ weaknesses methodically.'
      ],
      exploitation: [
        'Avoid obvious mistakes; they’ll capitalize on them.',
        'Play balanced, solid poker to minimize their edge.',
        'Mix up your play to remain unpredictable.'
      ],
    },
    {
      title: 'The TAG (Tight-Aggressive)',
      characteristics: [
        'Plays fewer hands but aggressively post-flop.',
        'Applies pressure with well-timed bets and raises.',
        'Solid, often profitable style.'
      ],
      exploitation: [
        'Don’t tangle without a strong hand.',
        'Test their discipline with occasional small bluffs.',
        'Respect their action but steal pots when they show weakness.'
      ],
    },
    {
      title: 'The LAG (Loose-Aggressive)',
      characteristics: [
        'Plays many hands aggressively, tough to handle.',
        'More calculated than a maniac, uses pressure skillfully.',
        'Difficult to put on a hand due to wide ranges.'
      ],
      exploitation: [
        'Tighten your range and wait for strong holdings.',
        'Trap with big hands; they’ll pay you off.',
        'Don’t be bullied; make mathematically sound calls.'
      ],
    },
    {
      title: 'The Rock',
      characteristics: [
        'Even tighter than a nit, barely plays any hands.',
        'Only bets or raises with a monster hand.',
        'Extremely predictable and too cautious.'
      ],
      exploitation: [
        'Steal blinds and antes relentlessly.',
        'If they show aggression, fold unless you have a monster.',
        'Gather chips slowly through uncontested pots.'
      ],
    },
    {
      title: 'The Recreational Player',
      characteristics: [
        'Plays for fun rather than profit.',
        'Can be unpredictable, shifting between loose and tight play.',
        'Not always logical, influenced by mood and experience level.'
      ],
      exploitation: [
        'Observe them closely; adjust on the fly.',
        'Use solid fundamentals to outplay their inconsistent approach.',
        'Capitalize on their mistakes without overcomplicating your strategy.'
      ],
    },
    {
      title: 'The Grinder',
      characteristics: [
        'Methodical, focused on consistent profit.',
        'Often plays lower stakes, avoiding high variance.',
        'Disciplined and patient, rarely makes big mistakes.'
      ],
      exploitation: [
        'Identify patterns and exploit their predictability.',
        'Apply selective aggression to push them out of their comfort zone.',
        'Don’t give away easy chips; they’ll capitalize slowly but surely.'
      ],
    },
    {
      title: 'The Whale',
      characteristics: [
        'Wealthy player who gambles without much skill.',
        'Plays loose and calls large bets with marginal hands.',
        'Not concerned about losses, often just for entertainment.'
      ],
      exploitation: [
        'Value-bet heavily with strong hands.',
        'Avoid fancy bluffs; they’ll call anyway.',
        'Consistently extract chips as they play too loosely.'
      ],
    },
    {
      title: 'Old Man Coffee (OMC)',
      characteristics: [
        'Ultra-tight, older player with a predictable style.',
        'Rarely adapts, sticks to a safe, straightforward approach.',
        'Plays only premium hands and avoids complex spots.'
      ],
      exploitation: [
        'Steal their blinds frequently; they fold too often.',
        'Respect their raises; they rarely bluff.',
        'Chip away steadily by exploiting their passivity.'
      ],
    },
    {
      title: 'The ABC Player',
      characteristics: [
        'Straightforward, “by-the-book” approach.',
        'Bets strong hands, checks or folds weak ones.',
        'Very predictable, lacking advanced plays.'
      ],
      exploitation: [
        'Bluff selectively when they show weakness.',
        'Exploit their predictability by mixing up your own style.',
        'Force them to make tough decisions outside their comfort zone.'
      ],
    },
    {
      title: 'The Weekend Warrior',
      characteristics: [
        'A recreational player who appears sporadically (weekends, holidays).',
        'Might loosen up after a few drinks or a winning streak.',
        'Motivated by fun, less by strategic profit.'
      ],
      exploitation: [
        'Identify when they’re in a calling mood and value-bet more.',
        'Adjust as their style shifts throughout the session.',
        'Use solid fundamentals to outlast their volatility.'
      ],
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col">
      {/* Logo Banner */}
      <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
        <img src={logo} alt="SettleStack Logo" className="h-32 w-auto" />
      </header>

      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
        <h2 className="text-3xl font-bold text-poker-gold mb-8 text-center">
          Understanding and Exploiting Different Poker Player Archetypes
        </h2>
        <p className="text-yellow-200 mb-6 text-center">
          Adapt to each style, exploit their weaknesses, and become a chameleon at the table.
        </p>
        
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {playerTypes.map((type, idx) => (
            <Card key={idx} className="bg-card-bg border-poker-gold backdrop-blur-sm hover:shadow-lg transition cursor-pointer">
              <CardContent className="p-6 flex flex-col">
                <h3 className="text-2xl font-semibold text-poker-gold mb-3">
                  {type.title}
                </h3>
                <div className="mb-3">
                  <h4 className="text-lg font-bold text-yellow-200 mb-1">Characteristics:</h4>
                  <ul className="list-disc list-inside text-yellow-200 text-sm space-y-1">
                    {type.characteristics.map((char, cIdx) => (
                      <li key={cIdx}>{char}</li>
                    ))}
                  </ul>
                </div>
                <div className="mb-4">
                  <h4 className="text-lg font-bold text-yellow-200 mb-1">How to Exploit:</h4>
                  <ul className="list-disc list-inside text-yellow-200 text-sm space-y-1">
                    {type.exploitation.map((exp, eIdx) => (
                      <li key={eIdx}>{exp}</li>
                    ))}
                  </ul>
                </div>
                <div className="mt-auto flex justify-center">
                  <Button
                    className="bg-poker-gold text-poker-dark hover:bg-yellow-400"
                    onClick={() => navigate('/resources')}
                    aria-label="Back to Resources"
                  >
                    « Back to Resources
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-poker-dark p-6">
        <div className="max-w-7xl mx-auto text-center text-poker-gold">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default PlayerTypes;
