import React, { useState, useEffect } from 'react';
import { calculateStatistics, calculateTotalTimePlayed } from '../../utils/stats';
import { gameService } from '../../services/game.service';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';

const PersonalStats = () => {
  const { user } = useAuth();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gameData = await gameService.getUserGames();
        setGames(gameData);
  
        // Calculate stats
        const calculatedStats = calculateStatistics(gameData, user?.id);
        setStats(calculatedStats);
  
        // Calculate total time played
        const totalTimePlayed = calculateTotalTimePlayed(gameData);
        setStats((prevStats) => ({
          ...prevStats,
          totalTimePlayed,
        }));
      } catch (error) {
        setError('Failed to fetch game history');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchGames();
  }, [user]);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center h-64"
      >
        <div className="text-lg text-white animate-pulse">Loading your personal stats...</div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center h-64"
      >
        <div className="text-red-500 text-center">{error}</div>
      </motion.div>
    );
  }

  const statCards = [
    {
      title: 'Overall Performance',
      stats: [
        { label: 'Total Games', value: stats.totalGames, color: 'text-white' },
        { label: 'Win Rate', value: `${stats.winRate}%`, color: 'text-white' },
        {
          label: 'Net Profit',
          value: stats.netProfit,
          color: stats.netProfit >= 0 ? 'text-green-400' : 'text-red-400',
        },
        { label: 'Total Buy-ins', value: stats.totalBuyins, color: 'text-white' },
        {
          label: 'Total Game Time',
          value: stats.totalTimePlayed[user.id] || '0h 0m 0s', // Show formatted time for the current user
          color: 'text-white',
        },
      ],
    },
    {
      title: 'Best Performance',
      stats: [
        { label: 'Biggest Win', value: stats.biggestWin, color: 'text-green-400' },
        { label: 'Best Game', value: stats.bestGameDate, color: 'text-white' },
        { label: 'Highest Win Streak', value: stats.highestWinStreak, color: 'text-white' },
        { label: 'Current Win Streak', value: stats.currentWinStreak, color: 'text-white' },
      ],
    },
    {
      title: 'Worst Performance',
      stats: [
        { label: 'Biggest Loss', value: stats.biggestLoss, color: 'text-red-400' },
        { label: 'Worst Game', value: stats.worstGameDate, color: 'text-white' },
        { label: 'Highest Loss Streak', value: stats.highestLossStreak, color: 'text-white' },
        { label: 'Current Loss Streak', value: stats.currentLossStreak, color: 'text-white' },
      ],
    },
    {
      title: 'Average Stats',
      stats: [
        { label: 'Avg Buy-In', value: stats.avgBuyIn, color: 'text-white' },
        {
          label: 'Avg Profit',
          value: stats.avgProfit,
          color: stats.avgProfit >= 0 ? 'text-white' : 'text-red-400',
        },
        { label: 'Total Wins', value: stats.totalWins, color: 'text-white' },
        { label: 'Total Losses', value: stats.totalLosses, color: 'text-white' },
      ],
    },
    {
      title: 'Monthly Performance',
      stats: [
        {
          label: 'Best Month',
          value: stats.bestMonth?.profit
            ? `${stats.bestMonth.month}: ${stats.bestMonth.profit}`
            : 'N/A',
          color: 'text-white',
        },
        {
          label: 'Worst Month',
          value: stats.worstMonth?.profit
            ? `${stats.worstMonth.month}: ${stats.worstMonth.profit}`
            : 'N/A',
          color: 'text-white',
        },
        { label: 'Break Even Games', value: stats.totalBreakeven, color: 'text-white' },
      ],
    },
  ];

  // Animation variants for container and items
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="space-y-6 px-4 py-6"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Stats Grid */}
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {statCards.map((card, index) => (
          <motion.div
            key={index}
            className="bg-green-900 border-2 border-yellow-500 rounded-lg p-6"
            variants={cardVariants}
            whileHover={{ scale: 1.02 }}
          >
            <h3 className="text-yellow-500 text-xl font-bold mb-4">{card.title}</h3>
            <div className="space-y-3">
              {card.stats.map((stat, statIndex) => (
                <motion.div
                  key={statIndex}
                  className="flex justify-between items-center"
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: statIndex * 0.05 }}
                >
                  <span className="text-gray-300">{stat.label}</span>
                  <span className={`text-lg font-semibold ${stat.color}`}>
                    {typeof stat.value === 'number'
                      ? formatCurrency(stat.value, stat.label)
                      : stat.value}
                  </span>
                </motion.div>
              ))}
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

// Utility function to format currency
const formatCurrency = (value, label) => {
  if (typeof value !== 'number') return value;

  // Skip currency formatting for specific labels
  const noCurrencyLabels = [
    'Total Games',
    'Total Wins',
    'Total Losses',
    'Highest Win Streak',
    'Current Win Streak',
    'Highest Loss Streak',
    'Current Loss Streak',
  ];

  if (noCurrencyLabels.includes(label)) {
    return value;
  }

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export default PersonalStats;
