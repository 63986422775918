import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import logo from '../assets/logo.png';
import { motion } from 'framer-motion';

function EquityAndOdds() {
  const navigate = useNavigate();

  const categories = [
    {
      heading: 'Foundational Mathematics',
      description: 'Begin with the core mathematical principles that inform every poker decision you make.',
      topics: [
        {
          title: 'Equity',
          description: 'Equity quantifies the percentage share of the pot you can expect to win in the long run, given the current state of the cards and assuming all hands are played to showdown.',
          details: [
            'To calculate equity, consider all possible remaining outcomes and their probabilities.',
            'As community cards are revealed, your equity changes dynamically.',
            'A practical way to understand equity: If you and one opponent both go all-in preflop with equal chances, you each have 50% equity. If you would win half the outcomes, you expect to win half the pot in the long run.',
            'Example: Suppose you hold a flush draw on the flop (9 outs). If the pot is $100, and you have about a 36% chance of completing your flush by the river, your equity in that pot is approximately $36.'
          ]
        },
        {
          title: 'Hand Odds & Outs',
          description: 'Hand odds measure the likelihood of improving your current holding. "Outs" are cards that can complete your draw to a stronger hand.',
          details: [
            'Count your outs: For example, a flush draw typically has 9 outs. A straight draw can have 8 outs.',
            'Use the "Rule of 2 and 4": If two cards are yet to come, multiply the number of your outs by 4 to estimate your percentage chance. If only one card remains, multiply by 2.',
            'Example: With 9 outs and two cards to come, your chance is roughly 9 x 4 = 36%. With one card to come, 9 x 2 = 18%.',
            'Refine these approximations using exact odds charts or combinatorial calculations for increased precision.'
          ]
        },
        {
          title: 'Expected Value (EV)',
          description: 'Expected Value (EV) is the long-term average result of a particular decision, measured in chips or monetary units.',
          details: [
            'EV = (Probability of Winning) × (Amount Won) - (Probability of Losing) × (Amount Lost).',
            'A +EV decision is profitable over many iterations, even if you occasionally lose in the short term.',
            'Example: If you call a $10 bet with a 40% chance to win a $50 pot, EV = 0.4 × $50 - 0.6 × $10 = $20 - $6 = +$14. This indicates a profitable long-term call.'
          ]
        }
      ]
    },
    {
      heading: 'Odds and Ratios for Decision-Making',
      description: 'Use mathematical ratios to guide your calls, bets, and folds. Understand how pot odds, implied odds, and break-even points help quantify profitability.',
      topics: [
        {
          title: 'Pot Odds',
          description: 'Pot odds compare the current pot size to the cost of your call, helping you determine if calling is mathematically sound.',
          details: [
            'Pot odds = (Amount You Could Win) : (Amount You Must Invest).',
            'If your probability of improving your hand (derived from hand odds) exceeds the ratio offered by the pot odds, calling is often correct.',
            'Example: If the pot is $90 and you must call $10, your pot odds are 9:1. If your chance of winning is greater than 10%, calling is generally correct.'
          ]
        },
        {
          title: 'Implied Odds',
          description: 'Implied odds consider not only the current pot but also the additional funds you may win from future bets if you hit your hand.',
          details: [
            'Implied odds are especially valuable when your opponents are likely to invest more money after you complete your draw.',
            'For example, if you call $10 into a $90 pot for a flush draw, your raw pot odds might be borderline. However, if you expect to win an additional $50 when you hit, your effective odds improve, potentially making the call profitable.',
            'Accurately estimating implied odds requires insight into opponent tendencies and future betting patterns.'
          ]
        },
        {
          title: 'Break-Even Points & Frequencies',
          description: 'Break-even analysis shows how often a particular action must succeed to avoid losing money.',
          details: [
            'Break-even frequency = Risk / (Risk + Reward).',
            'Example: If you bluff $100 into a $100 pot, your total potential pot after the bet is $200. You are risking $100 to win $100, so you need your bluff to work at least 50% of the time to break even.',
            'Apply break-even frequencies to decisions involving bluffs, value bets, and calls to maintain a profit-oriented approach.'
          ]
        }
      ]
    },
    {
      heading: 'Strategic Integration',
      description: 'Combine all these concepts into a cohesive strategic framework for consistent, profitable play.',
      topics: [
        {
          title: 'Putting It All Together',
          description: 'Integrating equity calculations, hand odds, pot odds, implied odds, and EV analysis allows you to make optimal, data-driven poker decisions.',
          details: [
            'Step 1: Assess your outs and calculate your chances of improvement.',
            'Step 2: Compare your probabilities to pot odds and consider implied odds for future gain.',
            'Step 3: Evaluate the EV of each decision and ensure it meets or exceeds your break-even frequency for long-term profitability.',
            'Over time, consistently applying these calculations leads to more informed strategy and sustainable success.'
          ]
        }
      ]
    }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col"
    >
      {/* Logo Banner */}
      <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
        <motion.img 
          src={logo} 
          alt="SettleStack Logo" 
          className="h-32 w-auto"
          animate={{ rotateY: [0, 360] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatDelay: 5,
          }}
        />
      </header>

      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
        <motion.h2
          className="text-3xl font-bold text-poker-gold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Master the Math Behind Poker
        </motion.h2>
        <motion.p
          className="text-yellow-200 mb-10 text-center max-w-2xl mx-auto leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          Enhance your poker decisions by grounding them in solid mathematical principles. Learn how to calculate equity, interpret pot odds, incorporate implied odds, and apply EV analysis to inform a profitable, long-term strategy.
        </motion.p>

        {categories.map((category, cIdx) => (
          <section key={cIdx} className="mb-12">
            <motion.h3
              className="text-2xl font-bold text-poker-gold mb-4 text-center"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: cIdx * 0.2 }}
            >
              {category.heading}
            </motion.h3>
            <motion.p
              className="text-yellow-200 mb-8 text-center max-w-3xl mx-auto leading-relaxed"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: cIdx * 0.3 }}
            >
              {category.description}
            </motion.p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.topics.map((topic, tIdx) => (
                <motion.div
                  key={tIdx}
                  className="bg-card-bg border-poker-gold backdrop-blur-sm hover:shadow-lg transition cursor-pointer p-6 rounded-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: (cIdx + tIdx) * 0.2 }}
                >
                  <h4 className="text-xl font-semibold text-poker-gold mb-3">
                    {topic.title}
                  </h4>
                  <p className="text-yellow-200 text-sm leading-relaxed mb-3">
                    {topic.description}
                  </p>
                  <ul className="list-disc list-inside text-yellow-200 text-sm space-y-1 mb-4">
                    {topic.details.map((detail, dIdx) => (
                      <li key={dIdx}>{detail}</li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </section>
        ))}

        {/* Back Button */}
        <motion.div
          className="mt-8 flex justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: categories.length * 0.2 }}
        >
          <Button
            className="bg-poker-gold text-poker-dark hover:bg-yellow-400"
            onClick={() => navigate('/resources')}
            aria-label="Back to Resources"
          >
            « Back to Resources
          </Button>
        </motion.div>
      </main>

      {/* Footer */}
      <footer className="bg-poker-dark p-6">
        <div className="max-w-7xl mx-auto text-center text-poker-gold">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </motion.div>
  );
}

export default EquityAndOdds;
