import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { useToast } from '../ui/use-toast';

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const token = searchParams.get('token');
      console.log('Token retrieved from URL:', token); // **Log 1: Token Debugging**

      const response = await fetch('/api/auth/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password }),
      });

      console.log('Response status:', response.status); // **Log 2: Response Status**

      if (response.ok) {
        console.log('Password reset successful'); // **Log 3: Success**
        toast({
          title: "Password updated ♠️",
          description: "Your password has been reset successfully",
          variant: "success",
        });
        navigate('/login');
      } else {
        const errorData = await response.json(); // Parse error details
        console.error('Error response body:', errorData); // **Log 4: Error Body**
        setError(errorData.error || 'Failed to reset password');
        throw new Error(errorData.error || 'Failed to reset password');
      }
    } catch (err) {
      console.error('Error during reset-password process:', err); // **Log 5: Catch Block Debugging**
      setError('Failed to reset password. Please try again.');
      toast({
        title: "Error ♣️",
        description: "Failed to reset password",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-green-900 to-green-800">
      <Card className="w-full max-w-md bg-black/50 border-yellow-500 backdrop-blur-sm">
        <CardHeader>
          <CardTitle className="text-2xl text-center text-yellow-500">
            Reset Password
          </CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="New password"
                required
                className="bg-black/20 border-yellow-500 text-yellow-500 placeholder-yellow-500/30"
                disabled={isLoading}
              />
            </div>

            <div>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                required
                className="bg-black/20 border-yellow-500 text-yellow-500 placeholder-yellow-500/30"
                disabled={isLoading}
              />
            </div>
            
            <Button 
              type="submit"
              className="w-full bg-yellow-500 text-black hover:bg-yellow-400"
              disabled={isLoading}
            >
              {isLoading ? 'Resetting...' : 'Reset Password'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ResetPassword;
