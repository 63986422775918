// src/components/auth/TermsOfService.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';

function TermsOfService() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-900/50 p-4">
      <Card className="w-full max-w-4xl bg-green-800 border-yellow-500 shadow-lg overflow-auto max-h-[90vh]">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center text-yellow-500">Terms of Service</CardTitle>
          <p className="text-center text-yellow-500">Last Updated: December 2, 2024</p>
        </CardHeader>

        <CardContent className="text-yellow-500 overflow-y-auto">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">1. Introduction</h2>
            <p>
              Welcome to <strong>SettleStack</strong> ("we," "our," or "us"). These Terms of Service ("Terms") govern your access to and use of our web application located at [yourwebsite.com] (the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.
            </p>

            <h2 className="text-xl font-semibold">2. Acceptance of Terms</h2>
            <p>
              By accessing or using SettleStack, you affirm that you are at least 18 years old and capable of entering into legally binding contracts. If you are using the Service on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
            </p>

            <h2 className="text-xl font-semibold">3. Description of Service</h2>
            <p>
              SettleStack is a web application designed to help users manage poker game debts and settlements. Key features include:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>User Authentication:</strong> Secure login using JWT (JSON Web Tokens).</li>
              <li><strong>Game Management:</strong> Create and manage poker games.</li>
              <li><strong>Player Tracking:</strong> Track player buy-ins, balances, and settlements.</li>
              <li><strong>Debt Calculations:</strong> Automated calculations for optimal debt settlements.</li>
              <li><strong>Payment Integration:</strong> Integration with Venmo for processing payments.</li>
              <li><strong>History and Statistics:</strong> Track historical data and view statistics related to games and settlements.</li>
            </ul>

            <h2 className="text-xl font-semibold">4. User Accounts</h2>
            <h3 className="text-lg font-semibold">4.1. Registration</h3>
            <p>
              To access certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate.
            </p>

            <h3 className="text-lg font-semibold">4.2. Account Security</h3>
            <p>
              You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to immediately notify us at <a href="mailto:settlestack@gmail.com" className="underline">settlestack@gmail.com</a> of any unauthorized use of your account or any other breach of security.
            </p>

            <h3 className="text-lg font-semibold">4.3. Account Termination</h3>
            <p>
              We reserve the right to suspend or terminate your account at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Service, us, or third parties, or for any other reason.
            </p>

            <h2 className="text-xl font-semibold">5. User Responsibilities</h2>
            <h3 className="text-lg font-semibold">5.1. Compliance with Laws</h3>
            <p>
              You agree to use the Service in compliance with all applicable local, state, national, and international laws and regulations.
            </p>

            <h3 className="text-lg font-semibold">5.2. Prohibited Activities</h3>
            <p>You agree not to:</p>
            <ul className="list-disc list-inside">
              <li>Use the Service for any unlawful purpose.</li>
              <li>Attempt to gain unauthorized access to any part of the Service.</li>
              <li>Interfere with the proper functioning of the Service.</li>
              <li>Use automated means to access the Service for any purpose without our express written permission.</li>
              <li>Violate the rights of others, including intellectual property rights.</li>
              <li>Transmit any viruses or malicious code.</li>
            </ul>

            <h2 className="text-xl font-semibold">6. Payment Terms</h2>
            <h3 className="text-lg font-semibold">6.1. Fees</h3>
            <p>
              SettleStack offers a free tier and a premium subscription plan. The premium plan includes additional features such as advanced analytics and priority support. Detailed information about fees, billing cycles, and available plans can be found on our <a href="/pricing" className="underline">Pricing Page</a>.
            </p>

            <h3 className="text-lg font-semibold">6.2. Billing</h3>
            <p>
              Payments for premium services are processed through Venmo. By using the premium features, you agree to provide accurate and complete payment information and to keep it updated.
            </p>

            <h3 className="text-lg font-semibold">6.3. Refund Policy</h3>
            <p>
              All payments are non-refundable except as required by law or as otherwise specified in these Terms. If you believe you are entitled to a refund, please contact us at <a href="mailto:settlestack@gmail.com" className="underline">settlestack@gmail.com</a>.
            </p>

            <h2 className="text-xl font-semibold">7. Intellectual Property</h2>
            <h3 className="text-lg font-semibold">7.1. Ownership</h3>
            <p>
              All content, features, and functionality of the Service, including but not limited to text, graphics, logos, images, software, and trademarks, are the exclusive property of SettleStack or its licensors and are protected by international copyright, trademark, patent, and other intellectual property laws.
            </p>

            <h3 className="text-lg font-semibold">7.2. Limited License</h3>
            <p>
              We grant you a limited, non-exclusive, non-transferable, and revocable license to use the Service for your personal or internal business purposes, subject to these Terms. You may not:
            </p>
            <ul className="list-disc list-inside">
              <li>Modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any content obtained from the Service.</li>
              <li>Reverse engineer, decompile, or disassemble any aspect of the Service.</li>
            </ul>

            <h2 className="text-xl font-semibold">8. User-Generated Content</h2>
            <h3 className="text-lg font-semibold">8.1. Ownership</h3>
            <p>
              You retain ownership of any content you create and submit through the Service ("User Content"). However, by submitting User Content, you grant SettleStack a worldwide, non-exclusive, royalty-free, transferable, and sublicensable license to use, reproduce, distribute, prepare derivative works of, display, and perform your User Content in connection with providing and promoting the Service.
            </p>

            <h3 className="text-lg font-semibold">8.2. Responsibility for User Content</h3>
            <p>
              You are solely responsible for your User Content and the consequences of submitting it. You agree not to submit any User Content that is unlawful, defamatory, infringes on intellectual property rights, or violates any other rights of any third party.
            </p>

            <h2 className="text-xl font-semibold">9. Termination</h2>
            <p>
              We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason, including if you breach these Terms.
            </p>
            <p>
              Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
            </p>

            <h2 className="text-xl font-semibold">10. Disclaimers and Limitation of Liability</h2>
            <h3 className="text-lg font-semibold">10.1. Disclaimer of Warranties</h3>
            <p>
              The Service is provided on an "AS IS" and "AS AVAILABLE" basis. SettleStack makes no warranties, express or implied, regarding the Service's operation or the information, content, materials, or products included on the Service.
            </p>

            <h3 className="text-lg font-semibold">10.2. Limitation of Liability</h3>
            <p>
              To the fullest extent permitted by law, SettleStack shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
            </p>
            <ul className="list-disc list-inside">
              <li>Your use or inability to use the Service.</li>
              <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
              <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service by any third party.</li>
              <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service.</li>
            </ul>

            <h3 className="text-lg font-semibold">10.3. Indemnification</h3>
            <p>
              You agree to defend, indemnify, and hold harmless SettleStack and its affiliates, licensors, and service providers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or relating to your violation of these Terms or your use of the Service.
            </p>

            <h2 className="text-xl font-semibold">11. Governing Law</h2>
            <p>
              These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.
            </p>

            <h2 className="text-xl font-semibold">12. Dispute Resolution</h2>
            <h3 className="text-lg font-semibold">12.1. Informal Resolution</h3>
            <p>
              We encourage you to contact us directly at <a href="mailto:settlestack@gmail.com" className="underline">settlestack@gmail.com</a> with any questions or concerns regarding these Terms or the Service before initiating any dispute.
            </p>

            <h3 className="text-lg font-semibold">12.2. Arbitration</h3>
            <p>
              Any disputes arising out of or related to these Terms or the Service shall be resolved through binding arbitration in [Your Jurisdiction], except that either party may seek injunctive relief in a court of competent jurisdiction.
            </p>

            <h3 className="text-lg font-semibold">12.3. Class Action Waiver</h3>
            <p>
              All claims must be brought individually, and you and SettleStack waive any right to pursue claims as a plaintiff or class member in any purported class or representative proceeding.
            </p>

            <h2 className="text-xl font-semibold">13. Changes to Terms</h2>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
            </p>
            <p>
              By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
            </p>

            <h2 className="text-xl font-semibold">14. Contact Information</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <ul className="list-disc list-inside">
              <li><strong>Email:</strong> <a href="mailto:settlestack@gmail.com" className="underline">settlestack@gmail.com</a></li>
            </ul>
          </div>
        </CardContent>

        <CardFooter className="flex justify-center">
          <Button
            onClick={() => navigate('/')}
            className="bg-yellow-500 text-black hover:bg-yellow-400"
          >
            Back to Home
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default TermsOfService;
