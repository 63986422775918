import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import logo from '../assets/logo.png';

function CommonMistakes() {
  const navigate = useNavigate();

  const mistakes = [
    {
      title: 'Playing Too Many Hands',
      explanation: [
        'A common rookie mistake is getting involved in too many pots.',
        'Leads to weak post-flop situations with marginal hands.',
        'Often results in losing chips steadily over time.'
      ],
      solutions: [
        'Tighten your pre-flop range and focus on strong starting hands.',
        'Consider your position at the table before entering a pot.',
        'Learn to fold hands that don’t have good potential.'
      ]
    },
    {
      title: 'Failing to Consider Position',
      explanation: [
        'Ignoring position reduces your control of the hand.',
        'Early position play with weak hands often leads to difficult decisions.',
        'Makes you an easy target for aggressive players in later positions.'
      ],
      solutions: [
        'Play tighter in early positions and expand your range in late positions.',
        'Use your positional advantage to pressure weaker players.',
        'Be mindful of when to bet, call, or fold based on your position.'
      ]
    },
    {
      title: 'Overvaluing Hands',
      explanation: [
        'Overplaying hands like medium pairs or top pair weak kicker.',
        'Results in losing big pots to stronger hands.',
        'Common in players who fail to read the board or opponents.'
      ],
      solutions: [
        'Assess the strength of your hand relative to the board and opponent actions.',
        'Avoid committing too many chips with a marginal hand.',
        'Learn to spot signs of strength from your opponents.'
      ]
    },
    {
      title: 'Bluffing Too Often or at the Wrong Time',
      explanation: [
        'Excessive or poorly timed bluffs make you predictable and unprofitable.',
        'Bluffing into calling stations or multi-way pots rarely works.',
        'Weak or inconsistent bluffs erode your stack.'
      ],
      solutions: [
        'Bluff selectively against players likely to fold.',
        'Choose situations where your story makes sense based on prior action.',
        'Understand board textures and how they connect to perceived ranges.'
      ]
    },
    {
      title: 'Failing to Manage Your Bankroll',
      explanation: [
        'Playing at stakes too high for your bankroll risks busting quickly.',
        'Leads to emotional decision-making and tilt when losses occur.',
        'Reduces your ability to play optimally under financial pressure.'
      ],
      solutions: [
        'Stick to proper bankroll management guidelines (e.g., 50+ buy-ins for cash games).',
        'Move down in stakes if your bankroll takes significant hits.',
        'Separate your poker funds from other finances.'
      ]
    },
    {
      title: 'Ignoring Opponent Tendencies',
      explanation: [
        'Failing to adjust your play based on opponent styles and patterns.',
        'Leads to missed opportunities for exploitation.',
        'Playing the same way against all opponents is suboptimal.'
      ],
      solutions: [
        'Observe your opponents closely and categorize their playstyles.',
        'Exploit passive players with aggression and avoid bluffing calling stations.',
        'Adjust your strategy dynamically based on table dynamics.'
      ]
    },
    {
      title: 'Chasing Draws Without Odds',
      explanation: [
        'Calling large bets with insufficient pot odds to justify the draw.',
        'Results in losing chips over the long run.',
        'Misunderstanding the math of poker leads to costly mistakes.'
      ],
      solutions: [
        'Calculate pot odds and compare them to your drawing odds.',
        'Only chase draws if the math supports it or if there’s implied value.',
        'Avoid calling large bets out of frustration or hope.'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col">
      {/* Logo Banner */}
      <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
        <img src={logo} alt="SettleStack Logo" className="h-32 w-auto" />
      </header>

      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
        <h2 className="text-3xl font-bold text-poker-gold mb-8 text-center">
          Common Poker Mistakes and How to Avoid Them
        </h2>
        <p className="text-yellow-200 mb-6 text-center">
          Learn to identify and correct these pitfalls to elevate your game.
        </p>
        
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {mistakes.map((mistake, idx) => (
            <Card key={idx} className="bg-card-bg border-poker-gold backdrop-blur-sm hover:shadow-lg transition cursor-pointer">
              <CardContent className="p-6 flex flex-col">
                <h3 className="text-2xl font-semibold text-poker-gold mb-3">
                  {mistake.title}
                </h3>
                <div className="mb-3">
                  <h4 className="text-lg font-bold text-yellow-200 mb-1">Explanation:</h4>
                  <ul className="list-disc list-inside text-yellow-200 text-sm space-y-1">
                    {mistake.explanation.map((exp, eIdx) => (
                      <li key={eIdx}>{exp}</li>
                    ))}
                  </ul>
                </div>
                <div className="mb-4">
                  <h4 className="text-lg font-bold text-yellow-200 mb-1">Solutions:</h4>
                  <ul className="list-disc list-inside text-yellow-200 text-sm space-y-1">
                    {mistake.solutions.map((sol, sIdx) => (
                      <li key={sIdx}>{sol}</li>
                    ))}
                  </ul>
                </div>
                <div className="mt-auto flex justify-center">
                  <Button
                    className="bg-poker-gold text-poker-dark hover:bg-yellow-400"
                    onClick={() => navigate('/resources')}
                    aria-label="Back to Resources"
                  >
                    « Back to Resources
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-poker-dark p-6">
        <div className="max-w-7xl mx-auto text-center text-poker-gold">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default CommonMistakes;
