// src/services/game.service.js
import { authService } from './auth.service';

const API_URL = (process.env.REACT_APP_API_URL || 'http://localhost:5000').replace(/\/+$/, '');


console.log('game.service.js -> Resolved API_URL:', API_URL);

const joinPaths = (base, path) => {
    const cleanBase = base.replace(/\/+$/, ''); // Remove trailing slashes
    const cleanPath = path.replace(/^\/+/, ''); // Remove leading slashes
    return `${cleanBase}/${cleanPath}`;
};

class GameService {
    constructor() {
        this.authService = authService;
    }

    getAuthHeader() {
        const token = localStorage.getItem('accessToken');
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
    }

    async handleRequest(url, options = {}) {
        try {
            console.log('Making request to:', url);
            console.log('With headers:', {
                ...this.getAuthHeader(),
                ...options.headers
            });
    
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...this.getAuthHeader(),
                    ...options.headers
                }
            });
    
            console.log('Response status:', response.status);
    
            // Check the Content-Type header to determine response format
            const contentType = response.headers.get('content-type');
            const responseText = await response.text();
            console.log('Raw response:', responseText.substring(0, 200)); // Log first 200 chars
    
            if (!response.ok) {
                console.error('Response error details:', responseText);
                throw new Error(`Request failed with status ${response.status}`);
            }
    
            // Parse JSON response if Content-Type includes 'application/json'
            if (contentType && contentType.includes('application/json')) {
                try {
                    return JSON.parse(responseText);
                } catch (error) {
                    console.error('JSON parse error. Full response:', responseText);
                    throw new Error('Failed to parse JSON response');
                }
            } else {
                console.warn('Unexpected non-JSON response received:', responseText);
                // Optionally return raw text or handle as needed
                return responseText;
            }
        } catch (error) {
            console.error('Request error:', error.message || error);
            throw error;
        }
    }

    

    // Create a new game
    async createGame(gameData) {
        const url = joinPaths(API_URL, '/api/games');  // Ensure /api prefix is included
        console.log('Constructed URL:', url); // Debug the full URL
        console.log('API_URL:', API_URL);     // Debug base API URL
        console.log('Creating game with payload:', gameData); // Log the payload being sent
    
        // Add log for Authorization header
        console.log('Authorization Header Sent:', this.getAuthHeader());
    
        return this.handleRequest(url, {
            method: 'POST',
            body: JSON.stringify(gameData),
        });
    }


    async getUserGames() {
        const url = `${API_URL}/api/games/user`;
        console.log('game.service.js -> getUserGames URL:', url);
        return this.handleRequest(url);
    }

    // Get specific game by ID
    async getGameById(gameId) {
        const url = joinPaths(API_URL, `/api/games/${gameId}`);
        console.log('game.service.js -> getGameById URL:', url);
        return this.handleRequest(url);
    }

    // Join a game using join code
    async joinGame(payload) {
        const url = joinPaths(API_URL, '/api/games/join');
        console.log('game.service.js -> joinGame URL:', url);
        return this.handleRequest(url, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
    }

    // Start a game
    async startGame(gameId) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/start`);
        return this.handleRequest(url, {
            method: 'POST'
        });
    }

    // Update player buy-in
    async updatePlayerBuyIn(gameId, playerId, buyInAmount) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/players/${playerId}/buyin`);
        return this.handleRequest(url, {
            method: 'PUT',
            body: JSON.stringify({ buyInAmount })
        });
    }

    // Update game status
    async updateGameStatus(gameId, payload) {
        if (!gameId) {
            throw new Error('Game ID is required');
        }

        if (!payload || !payload.status) {
            throw new Error('Status payload is required and must include a status field');
        }

        console.log('Service sending payload:', JSON.stringify(payload, null, 2));

        const url = joinPaths(API_URL, `/api/games/${gameId}/status`);
        return this.handleRequest(url, {
            method: 'PATCH',
            body: JSON.stringify(payload)
        });
    }

    // Get game status
    async getGameStatus(gameId) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/status`);
        return this.handleRequest(url);
    }

    // Update player final balance
    async updatePlayerFinalBalance(gameId, playerId, finalBalance) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/players/${playerId}/final_balance`);
        return this.handleRequest(url, {
            method: 'PATCH',
            body: JSON.stringify({ finalBalance })
        });
    }

    async submitFinalBalances(gameId, gameData) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/final-balances`);
        return this.handleRequest(url, {
            method: 'PATCH',
            body: JSON.stringify(gameData)
        });
    }

    // Submit all final balances
    async submitAllFinalBalances(gameId, balanceUpdates) {
        try {
            const promises = balanceUpdates.map(({ playerId, finalBalance }) =>
                this.updatePlayerFinalBalance(gameId, playerId, finalBalance)
            );

            await Promise.all(promises);
            return { message: 'Final balances updated successfully' };
        } catch (error) {
            console.error('Error submitting final balances:', error);
            throw error;
        }
    }

    // End game and calculate settlements
    async endGame(gameId) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/end`);
        return this.handleRequest(url, {
            method: 'POST'
        });
    }

    async getGameStatistics() {
        const url = joinPaths(API_URL, '/api/games/statistics');
        return this.handleRequest(url);
    }
    
    // Get game settlements
    async getGameSettlements(gameId) {
        const url = joinPaths(API_URL, `/api/games/${gameId}/settlements`);
        return this.handleRequest(url);
    }
    async getAllGames() {
        const url = joinPaths(API_URL, '/api/games'); // Add /api prefix
        console.log('game.service.js -> getAllGames URL:', url);
        return this.handleRequest(url);
    }
}

export const gameService = new GameService();