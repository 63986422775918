// frontend/src/services/auth.service.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
const TOKEN_KEY = 'accessToken';
const USER_KEY = 'user';

class AuthService {
 constructor() {
   this.setupAxiosInterceptors();
 }

 async login(email, password) {
   try {
     const response = await axios.post(`${API_URL}/auth/login`, {
       email,
       password
     }, {
       withCredentials: true // Important for receiving cookies
     });
     
     if (response.data.accessToken) {
       localStorage.setItem(TOKEN_KEY, response.data.accessToken);
       localStorage.setItem(USER_KEY, JSON.stringify(response.data.user));
       
       // Update axios default header
       axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
     }
     
     return response.data;
   } catch (error) {
     throw this.handleError(error);
   }
 }

 async register(username, email, password, venmo_handle = null) {
   try {
     const response = await axios.post(`${API_URL}/auth/register`, {
       username,
       email,
       password,
       venmo_handle
     });
     
     if (response.data.accessToken) {
       localStorage.setItem(TOKEN_KEY, response.data.accessToken);
       localStorage.setItem(USER_KEY, JSON.stringify(response.data.user));
     }
     
     return response.data;
   } catch (error) {
     throw this.handleError(error);
   }
 }

 async logout() {
   try {
     // Call backend logout endpoint to invalidate refresh token
     await axios.post(`${API_URL}/auth/logout`, {}, {
       withCredentials: true
     });
   } catch (error) {
     console.error('Logout error:', error);
   } finally {
     // Clear local storage and axios headers regardless of server response
     localStorage.removeItem(TOKEN_KEY);
     localStorage.removeItem(USER_KEY);
     delete axios.defaults.headers.common['Authorization'];
   }
 }

 async refreshToken() {
   try {
     const response = await axios.post(`${API_URL}/auth/refresh-token`, {}, {
       withCredentials: true
     });
     
     if (response.data.accessToken) {
       localStorage.setItem(TOKEN_KEY, response.data.accessToken);
       axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
       return response.data.accessToken;
     }
     return null;
   } catch (error) {
     console.error('Token refresh failed:', error);
     this.logout();
     throw error;
   }
 }

 // In auth.service.js
 async getAllUsers() {
  try {
    const token = localStorage.getItem(TOKEN_KEY);  // Use the same TOKEN_KEY constant
    console.log('Current token:', token); // Debug log

    if (!token) {
      console.warn('No token found in localStorage');
      return [];
    }

    const response = await axios.get(`${API_URL}/api/users`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    console.log('Users response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

 setupAxiosInterceptors() {
   // Remove any existing interceptors
   axios.interceptors.request.eject(this.requestInterceptor);
   axios.interceptors.response.eject(this.responseInterceptor);

   // Request interceptor
   this.requestInterceptor = axios.interceptors.request.use(
     (config) => {
       const token = this.getToken();
       if (token) {
         config.headers['Authorization'] = `Bearer ${token}`;
       }
       return config;
     },
     (error) => {
       return Promise.reject(error);
     }
   );

   // Response interceptor
   this.responseInterceptor = axios.interceptors.response.use(
     (response) => response,
     async (error) => {
       const originalRequest = error.config;

       // Handle token expiration
       if (error.response?.data?.code === 'TOKEN_EXPIRED' && !originalRequest._retry) {
         originalRequest._retry = true;

         try {
           const newToken = await this.refreshToken();
           if (newToken) {
             originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
             return axios(originalRequest);
           }
         } catch (refreshError) {
           // If refresh fails, redirect to login
           this.logout();
           window.location.href = '/login';
           return Promise.reject(refreshError);
         }
       }

       // Handle other auth errors
       if (error.response?.status === 401 || 
           (error.response?.status === 403 && error.response?.data?.code !== 'TOKEN_EXPIRED')) {
         this.logout();
         window.location.href = '/login';
       }

       return Promise.reject(error);
     }
   );
 }

 getCurrentUser() {
   const userStr = localStorage.getItem(USER_KEY);
   return userStr ? JSON.parse(userStr) : null;
 }

 getToken() {
   return localStorage.getItem(TOKEN_KEY);
 }

 updateCurrentUser(userData) {
   localStorage.setItem(USER_KEY, JSON.stringify(userData));
 }

 handleError(error) {
   let errorMessage = 'An error occurred';
   let errorCode = 'UNKNOWN_ERROR';
   let status = error.response?.status;

   if (error.response?.data) {
     errorMessage = error.response.data.error || error.response.data.message || errorMessage;
     errorCode = error.response.data.code || errorCode;
   } else if (error.message) {
     errorMessage = error.message;
   }

   return {
     error: true,
     message: errorMessage,
     code: errorCode,
     status
   };
 }

 isAuthenticated() {
   const token = this.getToken();
   const user = this.getCurrentUser();
   return !!(token && user);
 }

 async updateProfile(userData) {
   try {
     const response = await axios.put(`${API_URL}/auth/profile`, userData);
     if (response.data.user) {
       this.updateCurrentUser(response.data.user);
     }
     return response.data;
   } catch (error) {
     throw this.handleError(error);
   }
 }

 // Helper method to check if a token needs refresh
 shouldRefreshToken() {
   const token = this.getToken();
   if (!token) return false;
   
   try {
     const payload = JSON.parse(atob(token.split('.')[1]));
     const expiresIn = payload.exp * 1000 - Date.now();
     // Refresh if token expires in less than 5 minutes
     return expiresIn < 300000;
   } catch {
     return false;
   }
 }
}

export const authService = new AuthService();