import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import logo from '../assets/logo.png';
import { motion } from 'framer-motion';
import { FaStar, FaHeart, FaChessKing, FaChessPawn, FaCrown } from 'react-icons/fa';
import { GiCardAceSpades, GiCardKingClubs, GiCardQueenHearts, GiStraightPipe, GiPokerHand } from 'react-icons/gi';

function HandRankings() {
  const navigate = useNavigate();

  const handRankings = [
    {
      title: 'Royal Flush',
      description: 'A, K, Q, J, 10 all of the same suit. The highest possible hand.',
      icon: <FaCrown className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Straight Flush',
      description: 'Five consecutive cards of the same suit. Next best after a Royal Flush.',
      icon: <GiStraightPipe className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Four of a Kind',
      description: 'Four cards of the same rank. For example: four Queens.',
      icon: <GiPokerHand className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Full House',
      description: 'A combination of three of a kind and a pair. For example: three 8s and two Kings.',
      icon: <GiCardQueenHearts className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Flush',
      description: 'Five cards of the same suit, but not in sequence. For example: A, J, 9, 6, 3 of Hearts.',
      icon: <FaStar className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Straight',
      description: 'Five consecutive cards of any suit. For example: 5♥, 6♣, 7♦, 8♠, 9♥.',
      icon: <GiStraightPipe className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Three of a Kind',
      description: 'Three cards of the same rank. For example: three 4s.',
      icon: <GiCardKingClubs className="text-poker-gold text-6xl" />,
    },
    {
      title: 'Two Pair',
      description: 'Two different pairs of cards. For example: two 10s and two 5s.',
      icon: <FaChessPawn className="text-poker-gold text-6xl" />,
    },
    {
      title: 'One Pair',
      description: 'Two cards of the same rank. For example: two Jacks.',
      icon: <FaHeart className="text-poker-gold text-6xl" />,
    },
    {
      title: 'High Card',
      description: 'If no one has any combination, the highest single card wins. For example: A high.',
      icon: <GiCardAceSpades className="text-poker-gold text-6xl" />,
    },
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="min-h-screen bg-gradient-to-b from-poker-green to-poker-dark flex flex-col"
    >
      {/* Logo Banner */}
      <header className="w-full bg-black/50 flex items-center justify-center p-6 border-b border-poker-gold">
        <motion.img 
          src={logo} 
          alt="SettleStack Logo" 
          className="h-32 w-auto"
          animate={{ rotateY: [0, 360] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatDelay: 5,
          }}
        />
      </header>

      <main className="flex-grow max-w-7xl mx-auto px-6 py-10">
        {/* Page Title */}
        <motion.h2
          className="text-3xl font-bold text-poker-gold mb-6 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Poker Hand Rankings & Texas Hold'em Basics
        </motion.h2>

        {/* Rules Section */}
        <motion.div
          className="bg-black/20 border-poker-gold rounded-lg p-6 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h3 className="text-2xl font-semibold text-poker-gold mb-4">Basic Rules of Texas Hold'em</h3>
          <p className="text-yellow-200 text-sm leading-relaxed">
            In Texas Hold'em, each player starts with two private cards (the "hole cards"). Over several betting rounds, 
            five community cards are dealt face-up on the table. Your goal is to make the best five-card poker hand from 
            the combination of your two hole cards and the five community cards.
          </p>
          <ul className="list-disc list-inside text-yellow-200 text-sm mt-4 space-y-2">
            <li>Each player receives two hole cards dealt face-down.</li>
            <li>Betting rounds occur before and after the "flop" (first three community cards), "turn" (fourth card), and "river" (fifth card).</li>
            <li>Players can fold, call, check, bet, or raise during each betting round.</li>
            <li>The best five-card hand wins the pot, unless all other players fold before showdown.</li>
          </ul>
        </motion.div>

        {/* Hand Rankings */}
        <motion.h3
          className="text-2xl font-semibold text-poker-gold mb-6 text-center"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Hand Rankings (Best to Worst)
        </motion.h3>

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {handRankings.map((hand, idx) => (
            <motion.div
              key={idx}
              className="flex flex-col items-center text-center bg-black/20 border border-poker-gold p-6 rounded-lg hover:shadow-lg transition"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: idx * 0.2 }}
            >
              <div className="mb-4">{hand.icon}</div>
              <h3 className="text-2xl font-semibold text-poker-gold">{hand.title}</h3>
              <p className="text-yellow-200 text-sm leading-relaxed mt-2">{hand.description}</p>
            </motion.div>
          ))}
        </section>

        {/* Back Button */}
        <motion.div
          className="mt-8 flex justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: handRankings.length * 0.2 }}
        >
          <Button
            className="bg-poker-gold text-poker-dark hover:bg-yellow-400"
            onClick={() => navigate('/resources')}
            aria-label="Back to Resources"
          >
            ← Back to Resources
          </Button>
        </motion.div>
      </main>

      {/* Footer */}
      <footer className="bg-poker-dark p-6">
        <div className="max-w-7xl mx-auto text-center text-poker-gold">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </motion.div>
  );
}

export default HandRankings;
