// frontend/src/pages/ManageProfile.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useToast } from '../components/ui/use-toast';
import { User, Save, Key, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import axios from '../utils/axios';

const ManageProfile = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const { toast } = useToast();

  // Profile Update States
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState('');

  const [profileData, setProfileData] = useState({
    username: user?.username || '',
    venmo_handle: user?.venmo_handle || '',
    profile_picture: user?.profile_picture || '',
  });

  // Password Change States
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const getImageUrl = (path) => {
    if (!path) return '';
    const cleanPath = path.replace(/^\/+/, '');
    return `${axios.defaults.baseURL}/${cleanPath}`;
  };

  useEffect(() => {
    if (user) {
      // Refresh profile data when component mounts or user changes
      setProfileData({
        username: user.username || '',
        venmo_handle: user.venmo_handle || '',
        profile_picture: user.profile_picture || '',
      });
    }
  }, [user]);

  // Helper function to extract error messages
  const extractErrorMessage = (errorResponse) => {
    if (!errorResponse) return 'An unknown error occurred.';
    if (typeof errorResponse === 'string') return errorResponse;
    if (errorResponse.message) return errorResponse.message;
    // Add more conditions if your backend sends different error structures
    return 'An error occurred. Please try again.';
  };

  // Handle input changes for Profile Update Form
  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle input changes for Password Change Form
  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Profile Update Form Submission
  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setProfileLoading(true);
    setProfileError('');

    if (!user?.id) {
      setProfileError('User ID not found. Please try logging in again.');
      setProfileLoading(false);
      return;
    }

    try {
      const response = await axios.put(
        `/profile`, // Assuming baseURL is set to /api/users
        {
          username: profileData.username,
          venmo_handle: profileData.venmo_handle,
          profile_picture: profileData.profile_picture,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      updateUser(response.data.user);
      toast({
        title: 'Profile Updated',
        description: 'Your profile has been successfully updated.',
        variant: 'success',
      });

      navigate('/');
    } catch (err) {
      console.error('Profile Update Error:', err.response?.data);
      setProfileError(extractErrorMessage(err.response?.data?.error));
    } finally {
      setProfileLoading(false);
    }
  };

  // Handle Password Change Form Submission
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordLoading(true);
    setPasswordError('');
  
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError('New passwords do not match.');
      setPasswordLoading(false);
      return;
    }
  
    try {
      await axios.post(
        '/api/auth/change-password', // Updated endpoint
        {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Ensure you're using 'accessToken'
          },
        }
      );
  
      toast({
        title: 'Password Changed',
        description: 'Your password has been successfully updated.',
        variant: 'success',
      });
  
      // Clear password fields after success
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (err) {
      console.error('Password Change Error:', err.response?.data);
      setPasswordError(extractErrorMessage(err.response?.data?.error));
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="dashboard-wrapper"
    >
      <div className="dashboard-container">
        <motion.nav
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="w-full mb-8 px-4"
        >
          <Button
            onClick={() => navigate('/dashboard')}
            variant="outline"
            className="bg-poker-yellow text-poker-black hover:bg-poker-gold hover:text-black transition-colors duration-200 font-semibold w-full md:w-auto flex items-center justify-center gap-2"
          >
            <ArrowLeft className="w-4 h-4" />
            Back to Dashboard
          </Button>
        </motion.nav>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Card className="dashboard-card max-w-2xl mx-auto">
            <CardHeader>
              <CardTitle className="card-title flex items-center">
                <User className="card-icon mr-2" />
                Edit Profile Details
              </CardTitle>
            </CardHeader>
            <CardContent>
              {/* Profile Update Error Alert */}
              {profileError && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                >
                  <Alert variant="destructive" className="mb-6">
                    <AlertDescription>{profileError}</AlertDescription>
                  </Alert>
                </motion.div>
              )}

              {/* Profile Update Form */}
              <form onSubmit={handleProfileSubmit} className="space-y-6">
                <motion.div
                  className="flex flex-col items-center mb-6"
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  {profileData.profile_picture ? (
                    <div className="w-24 h-24 rounded-full bg-poker-black-transparent border-2 border-poker-gold overflow-hidden">
                      <img
                        src={getImageUrl(profileData.profile_picture)}
                        alt="Profile Picture"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    <div className="w-24 h-24 rounded-full bg-gray-200 border-2 border-gray-400 flex items-center justify-center">
                      <User className="w-12 h-12 text-gray-500" />
                    </div>
                  )}

                  <Button
                    type="button"
                    onClick={() => navigate('/profile/manage/select-picture')}
                    className="mt-4 flex items-center gap-2"
                  >
                    <User className="w-4 h-4" />
                    Choose Profile Picture
                  </Button>
                </motion.div>

                <motion.div
                  className="space-y-2"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <Label htmlFor="username" className="text-poker-gold">
                    Display Name
                  </Label>
                  <Input
                    id="username"
                    name="username"
                    value={profileData.username}
                    onChange={handleProfileInputChange}
                    className="input-enhanced"
                    placeholder="Enter your display name"
                    required
                  />
                </motion.div>

                <motion.div
                  className="space-y-2"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <Label htmlFor="venmo_handle" className="text-poker-gold">
                    Venmo Handle
                  </Label>
                  <Input
                    id="venmo_handle"
                    name="venmo_handle"
                    value={profileData.venmo_handle}
                    onChange={handleProfileInputChange}
                    className="input-enhanced"
                    placeholder="@your-venmo-username"
                  />
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 }}
                >
                  <Button
                    type="submit"
                    className="button-enhanced w-full"
                    disabled={profileLoading || passwordLoading}
                  >
                    {profileLoading ? (
                      <>
                        <Save className="w-4 h-4 mr-2 animate-spin" />
                        Saving Changes...
                      </>
                    ) : (
                      <>
                        <Save className="w-4 h-4 mr-2" />
                        Save Changes
                      </>
                    )}
                  </Button>
                </motion.div>
              </form>

              {/* Password Change Error Alert */}
              {passwordError && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="mt-8"
                >
                  <Alert variant="destructive" className="mb-6">
                    <AlertDescription>{passwordError}</AlertDescription>
                  </Alert>
                </motion.div>
              )}

              {/* Password Change Form */}
              <form onSubmit={handlePasswordChange} className="space-y-6 mt-8">
                <motion.div
                  className="space-y-2"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6 }}
                >
                  <Label htmlFor="currentPassword" className="text-poker-gold">
                    Current Password
                  </Label>
                  <Input
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordInputChange}
                    className="input-enhanced"
                    placeholder="Enter your current password"
                    required
                  />
                </motion.div>

                <motion.div
                  className="space-y-2"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.7 }}
                >
                  <Label htmlFor="newPassword" className="text-poker-gold">
                    New Password
                  </Label>
                  <Input
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    value={passwordData.newPassword}
                    onChange={handlePasswordInputChange}
                    className="input-enhanced"
                    placeholder="Enter your new password"
                    required
                  />
                </motion.div>

                <motion.div
                  className="space-y-2"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.8 }}
                >
                  <Label htmlFor="confirmPassword" className="text-poker-gold">
                    Confirm New Password
                  </Label>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordInputChange}
                    className="input-enhanced"
                    placeholder="Confirm your new password"
                    required
                  />
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.9 }}
                >
                  <Button
                    type="submit"
                    className="button-enhanced w-full"
                    disabled={passwordLoading || profileLoading}
                  >
                    {passwordLoading ? (
                      <>
                        <Key className="w-4 h-4 mr-2 animate-spin" />
                        Changing Password...
                      </>
                    ) : (
                      <>
                        <Key className="w-4 h-4 mr-2" />
                        Change Password
                      </>
                    )}
                  </Button>
                </motion.div>
              </form>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ManageProfile;
