import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { DollarSign, Heart, Coffee } from 'lucide-react';
import { Button } from '../components/ui/button';

const DonatePage = () => {
  const handleDonate = () => {
    window.open('https://venmo.com/compass-co', '_blank');
  };

  return (
    <div className="min-h-screen py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <Heart className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
          <h1 className="text-4xl font-bold text-yellow-500 mb-4">Support SettleStack</h1>
          <p className="text-lg text-gray-400 mb-4">Help us keep the cards flowing and the debts settling!</p>
          <p className="text-lg text-yellow-500/80 italic">"Hit a big pot? Share the luck! Consider donating a small portion of your winnings to help keep SettleStack running smooth as your poker face."</p>
        </div>

        <Card className="bg-black/50 border-2 border-yellow-500/50 backdrop-blur-sm">
          <CardHeader>
            <CardTitle className="text-2xl text-center text-yellow-500">
              Buy Us a Coffee ☕
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="text-center space-y-4">
              <p className="text-gray-300">
                If SettleStack has helped your poker nights run smoother, consider buying us a coffee! 
                Every donation helps us maintain and improve the platform.
              </p>
              
              <div className="flex items-center justify-center space-x-2 text-yellow-500">
                <DollarSign className="w-6 h-6" />
                <span className="text-xl font-semibold">@compass-co</span>
              </div>
            </div>

            <div className="grid gap-4 md:grid-cols-3">
              <Card className="bg-black/30 border border-yellow-500/30 hover:border-yellow-500/60 transition-colors">
                <CardContent className="pt-6 text-center">
                  <Coffee className="w-8 h-8 text-yellow-500 mx-auto mb-2" />
                  <h3 className="text-lg font-semibold text-yellow-500 mb-2">Small Stack</h3>
                  <p className="text-3xl font-bold text-yellow-500 mb-2">$5</p>
                  <p className="text-sm text-gray-400">A quick thank you!</p>
                </CardContent>
              </Card>

              <Card className="bg-black/30 border border-yellow-500/30 hover:border-yellow-500/60 transition-colors">
                <CardContent className="pt-6 text-center">
                  <Coffee className="w-8 h-8 text-yellow-500 mx-auto mb-2" />
                  <h3 className="text-lg font-semibold text-yellow-500 mb-2">Medium Stack</h3>
                  <p className="text-3xl font-bold text-yellow-500 mb-2">$10</p>
                  <p className="text-sm text-gray-400">Really appreciate it!</p>
                </CardContent>
              </Card>

              <Card className="bg-black/30 border border-yellow-500/30 hover:border-yellow-500/60 transition-colors">
                <CardContent className="pt-6 text-center">
                  <Coffee className="w-8 h-8 text-yellow-500 mx-auto mb-2" />
                  <h3 className="text-lg font-semibold text-yellow-500 mb-2">Large Stack</h3>
                  <p className="text-3xl font-bold text-yellow-500 mb-2">$20</p>
                  <p className="text-sm text-gray-400">You're amazing!</p>
                </CardContent>
              </Card>
            </div>

            <div className="text-center pt-6">
              <Button
                onClick={handleDonate}
                className="inline-flex items-center justify-center"
              >
                <DollarSign className="w-4 h-4 mr-2" />
                Donate via Venmo
              </Button>
            </div>
          </CardContent>
        </Card>

        <div className="mt-8 text-center text-sm text-gray-500">
          <p>Your support helps us maintain and improve SettleStack for the entire poker community.</p>
          <p className="mt-2 italic">Stack it up today, share it forward tomorrow! 🎲</p>
        </div>
      </div>
    </div>
  );
};

export default DonatePage;