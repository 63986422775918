// src/utils/axios.js or src/api/axios.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const axiosInstance = axios.create({
    baseURL: API_URL, // Ensure the baseURL does not include `/api`
});

console.log('axios.js -> Axios instance baseURL:', axiosInstance.defaults.baseURL);

// Request interceptor to add JWT token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    console.log('axios.js -> Request Config:', config);
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('axios.js -> Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh when 401 (Unauthorized)
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Call the refresh token endpoint
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          console.log('axios.js -> Refreshing token...');
          const response = await axios.post('/auth/refresh', { refreshToken });
          const { accessToken } = response.data;

          // Save new access token and retry the original request
          localStorage.setItem('accessToken', accessToken);
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch (err) {
          console.error('Refresh token error', err);
        }
      }
    }
    console.error('axios.js -> Response Interceptor Error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
