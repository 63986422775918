import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { Users, Loader2, CalendarDays, DollarSign } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useGame } from '../../contexts/GameContext';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const GameSetup = () => {
  const { user } = useAuth();
  const { createGame } = useGame();
  const navigate = useNavigate();

  const [gameData, setGameData] = useState({
    date: new Date().toISOString().split('T')[0],
    buyIn: '',
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateGame = async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      // Validate Buy-In Amount
      if (!gameData.buyIn || isNaN(gameData.buyIn) || Number(gameData.buyIn) <= 0) {
        setError('Please provide a valid buy-in amount.');
        setIsLoading(false);
        return;
      }
  
      // Ensure Date Considers Local Timezone
      const localDate = new Date(`${gameData.date}T00:00:00`); // Add default time (midnight)
      const start_time = localDate.toISOString(); // Convert to UTC in ISO 8601 format
  
      // Validate start_time
      if (!start_time || isNaN(new Date(start_time).getTime())) {
        setError('Invalid start time.');
        setIsLoading(false);
        return;
      }
  
      // Define Payload
      const payload = {
        name: `Poker Game - ${localDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}`,
        default_buy_in: Number(gameData.buyIn),
        creator_id: user.id,
        status: 'setup',
        start_time, // Explicitly set the start_time in UTC
        duration: 60, // Default duration
      };
  
      console.log('Creating game with payload:', payload);
  
      // Create Game
      const newGame = await createGame(payload);
      console.log('Game created successfully:', newGame);
  
      // Navigate to Game Dashboard
      navigate(`/game/${newGame.id}/dashboard`);
    } catch (err) {
      console.error('Create game error:', err);
      setError(err.message || 'Failed to create game. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8 max-w-4xl"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card className="mb-6 bg-green-900/50 border-yellow-500">
          <CardHeader>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
            >
              <CardTitle className="flex items-center gap-2 text-yellow-500">
                <Users className="w-6 h-6" />
                Create New Game
              </CardTitle>
            </motion.div>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              {/* Date Input */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                <label className="block text-sm font-medium text-yellow-500 mb-1">
                  Game Date
                </label>
                <div className="relative">
                  <Input
                    type="date"
                    value={gameData.date}
                    onChange={(e) => setGameData(prev => ({ ...prev, date: e.target.value }))}
                    className="bg-black/20 border-yellow-500 text-yellow-500 pl-10"
                  />
                </div>
              </motion.div>

              {/* Buy-In Amount Input */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <label className="block text-sm font-medium text-yellow-500 mb-1">
                  Buy-In Amount ($)
                </label>
                <div className="relative">
                  <Input
                    type="number"
                    value={gameData.buyIn}
                    onChange={(e) => setGameData(prev => ({ ...prev, buyIn: e.target.value }))}
                    placeholder="Enter buy-in amount"
                    className="bg-black/20 border-yellow-500 text-yellow-500 pl-10 placeholder-yellow-500/30"
                  />
                </div>
              </motion.div>

              {error && (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Alert variant="destructive" className="border-red-500 bg-red-900/20">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                </motion.div>
              )}

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
              >
                <Button
                  className="w-full bg-yellow-500 text-black hover:bg-yellow-400"
                  size="lg"
                  onClick={handleCreateGame}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin mr-2" />
                  ) : (
                    <Users className="w-5 h-5 mr-2" />
                  )}
                  {isLoading ? 'Creating Game...' : 'Create Game'}
                </Button>
              </motion.div>
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </motion.div>
  );
};

export default GameSetup;