import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import { formatDate } from '../../utils/stats';
import { BarChart } from 'lucide-react';
import { Card } from '../ui/card';
import { motion } from 'framer-motion';

const GameHistory = ({ games }) => {
  const navigate = useNavigate();
  const [selectedGames, setSelectedGames] = useState([]);

  if (!Array.isArray(games) || games.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center h-64 px-4"
      >
        <span className="text-yellow-500 text-xl">No games found</span>
      </motion.div>
    );
  }

  const sortedGames = [...games].sort(
    (a, b) => new Date(b.start_time) - new Date(a.start_time)
  );

  const toggleGameSelection = (game) => {
    setSelectedGames((prev) => {
      const isSelected = prev.some((g) => g.id === game.id);
      if (isSelected) {
        return prev.filter((g) => g.id !== game.id);
      } else {
        return [...prev, game];
      }
    });
  };

  const handleAnalyzeSelected = () => {
    if (selectedGames.length > 0) {
      navigate(`/history/analyze?games=${selectedGames.map((g) => g.id).join(',')}`);
    }
  };

  const handleDeleteGame = async (gameId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this game?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`/api/games/${gameId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (response.ok) {
        alert('Game deleted successfully.');
        setSelectedGames((prev) => prev.filter((g) => g.id !== gameId));
      } else {
        alert('Failed to delete the game. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting game:', error);
      alert('An error occurred while trying to delete the game.');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6 px-4 py-6"
    >
      {/* Analysis Section */}
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col sm:flex-row items-center justify-between gap-4"
      >
        <span className="text-yellow-500 text-lg font-bold">
          {selectedGames.length > 0
            ? `${selectedGames.length} game${selectedGames.length !== 1 ? 's' : ''} selected`
            : 'Select games to analyze'}
        </span>
        <Button
          onClick={handleAnalyzeSelected}
          disabled={selectedGames.length === 0}
          className={`font-bold flex items-center justify-center ${
            selectedGames.length > 0
              ? 'bg-yellow-500 hover:bg-yellow-600 text-green-900'
              : 'bg-green-800 text-gray-500 cursor-not-allowed'
          }`}
        >
          <BarChart className="w-5 h-5 mr-2" />
          Analyze Games
        </Button>
      </motion.div>

      {/* Responsive Table or Card Layout */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-green-900 rounded-lg border-2 border-yellow-500 overflow-hidden"
      >
        {/* For Small Screens: Card Layout */}
        <div className="block sm:hidden">
          {sortedGames.map((game, idx) => (
            <motion.div
              key={game.id}
              className={`border-b border-green-800 p-4 ${idx % 2 === 0 ? 'bg-green-900/50' : 'bg-green-900'}`}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: idx * 0.05 }}
            >
              <div className="text-yellow-500 font-bold mb-2">{game.name}</div>
              <div className="text-white text-sm mb-1">
                <strong>Players:</strong> {game.players?.map((p) => p.name).join(', ')}
              </div>
              <div className="text-white text-sm mb-1">
                <strong>Date & Time:</strong> {formatDate(game.start_time)}
              </div>
              <div className="text-sm font-medium mb-2">
                <strong>Status:</strong>{' '}
                <span
                  className={`${
                    game.status === 'completed'
                      ? 'text-green-400'
                      : game.status === 'displaying_transactions'
                      ? 'text-blue-400'
                      : 'text-yellow-500'
                  }`}
                >
                  {game.status.replace(/_/g, ' ').toUpperCase()}
                </span>
              </div>
              <div className="flex gap-2 mt-2">
                <Button
                  onClick={() => toggleGameSelection(game)}
                  variant="outline"
                  className={`w-full ${
                    selectedGames.some((g) => g.id === game.id)
                      ? 'bg-yellow-500 text-green-900 hover:bg-yellow-600'
                      : 'border-yellow-500 text-yellow-500 hover:bg-green-800'
                  }`}
                >
                  {selectedGames.some((g) => g.id === game.id) ? 'Selected' : 'Select'}
                </Button>
                <Button
                  onClick={() => handleDeleteGame(game.id)}
                  variant="outline"
                  className="w-full border-red-500 text-red-500 hover:bg-red-800"
                >
                  Delete
                </Button>
              </div>
            </motion.div>
          ))}
        </div>

        {/* For Larger Screens: Table Layout */}
        <div className="hidden sm:block overflow-x-auto">
          <table className="w-full text-sm md:text-base min-w-[600px]">
            <thead>
              <tr className="bg-green-800 border-b-2 border-yellow-500">
                <th className="p-3 text-left font-bold text-yellow-500">Game</th>
                <th className="p-3 text-left font-bold text-yellow-500">Players</th>
                <th className="p-3 text-left font-bold text-yellow-500">Date & Time</th>
                <th className="p-3 text-left font-bold text-yellow-500">Status</th>
                <th className="p-3 text-left font-bold text-yellow-500">Action</th>
              </tr>
            </thead>
            <tbody>
              {sortedGames.map((game, idx) => (
                <motion.tr
                  key={game.id}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: idx * 0.05 }}
                  className={`border-b border-green-800 hover:bg-green-800 transition-colors ${
                    idx % 2 === 0 ? 'bg-green-900/50' : 'bg-green-900'
                  }`}
                >
                  <td className="p-3">
                    <span className="text-yellow-500 font-bold">{game.name}</span>
                  </td>
                  <td className="p-3 text-white">
                    {game.players?.map((p) => p.name).join(', ')}
                  </td>
                  <td className="p-3 text-white">{formatDate(game.start_time)}</td>
                  <td className="p-3">
                    <span
                      className={`font-medium ${
                        game.status === 'completed'
                          ? 'text-green-400'
                          : game.status === 'displaying_transactions'
                          ? 'text-blue-400'
                          : 'text-yellow-500'
                      }`}
                    >
                      {game.status.replace(/_/g, ' ').toUpperCase()}
                    </span>
                  </td>
                  <td className="p-3 flex gap-2">
                    <Button
                      onClick={() => toggleGameSelection(game)}
                      variant="outline"
                      className={`flex items-center justify-center ${
                        selectedGames.some((g) => g.id === game.id)
                          ? 'bg-yellow-500 text-green-900 hover:bg-yellow-600'
                          : 'border-yellow-500 text-yellow-500 hover:bg-green-800'
                      }`}
                    >
                      {selectedGames.some((g) => g.id === game.id) ? 'Selected' : 'Select'}
                    </Button>
                    <Button
                      onClick={() => handleDeleteGame(game.id)}
                      variant="outline"
                      className="flex items-center justify-center border-red-500 text-red-500 hover:bg-red-800"
                    >
                      Delete
                    </Button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default GameHistory;
