// frontend/src/pages/Contact.jsx

import React, { useState } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useToast } from '../components/ui/use-toast';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';

function Contact() {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    // Simulate form submission (replace with actual API call)
    try {
      // Example: await api.submitContactForm(formData);
      await new Promise(resolve => setTimeout(resolve, 2000));
      setSuccess(true);
      toast({
        title: "Message Sent!",
        description: "Thank you for reaching out to us. We'll get back to you shortly.",
        variant: "success",
      });
      setFormData({ name: '', email: '', message: '' });
    } catch (err) {
      console.error('Contact form error:', err);
      setError('Failed to send message. Please try again later.');
      toast({
        title: "Submission Failed",
        description: "Unable to send your message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-900 to-green-800 flex flex-col">
      {/* Header */}
      <header className="p-6 flex flex-col items-center">
        <img src={logo} alt="SettleStack Logo" className="h-16 w-16 rounded-full shadow mb-4" />
        <h1 className="text-yellow-300 text-3xl font-bold tracking-wide">Contact Us</h1>
      </header>

      {/* Main Content */}
      <main className="flex-grow max-w-2xl mx-auto px-6 py-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="space-y-6"
        >
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert variant="success">
              <AlertDescription>Your message has been sent successfully!</AlertDescription>
            </Alert>
          )}

          <Card className="bg-black/50 border-yellow-500 backdrop-blur-sm">
            <CardContent className="p-6">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="name" className="text-yellow-500">Name</Label>
                  <Input
                    id="name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    aria-label="Your Name"
                    className="bg-black/20 border-yellow-500 text-yellow-500 placeholder-yellow-500/40 focus:ring-yellow-400"
                    disabled={isSubmitting}
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="email" className="text-yellow-500">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    aria-label="Your Email"
                    className="bg-black/20 border-yellow-500 text-yellow-500 placeholder-yellow-500/40 focus:ring-yellow-400"
                    disabled={isSubmitting}
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="message" className="text-yellow-500">Message</Label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    aria-label="Your Message"
                    className="w-full bg-black/20 border-yellow-500 text-yellow-500 placeholder-yellow-500/40 focus:ring-yellow-400 rounded-md p-2 h-32"
                    disabled={isSubmitting}
                  ></textarea>
                </div>

                <Button
                  type="submit"
                  className="w-full bg-yellow-500 text-black hover:bg-yellow-400 flex items-center justify-center space-x-2"
                  disabled={isSubmitting}
                  aria-label="Send Message"
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </Button>
              </form>
            </CardContent>
          </Card>
        </motion.div>
      </main>

      {/* Footer */}
      <footer className="bg-green-800 p-6">
        <div className="max-w-7xl mx-auto text-center text-yellow-300">
          © {new Date().getFullYear()} SettleStack. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default Contact;
