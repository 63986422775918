// src/components/game/SubmitBalances.jsx

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { useGame } from "../../contexts/GameContext";
import { useAuth } from "../../contexts/AuthContext";

const SubmitBalances = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const { fetchGame, updateGameStatus, submitFinalBalances } = useGame();
  const { user } = useAuth();

  const [game, setGame] = useState(null);
  const [finalBalances, setFinalBalances] = useState({});
  const [buyIns, setBuyIns] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [polling, setPolling] = useState(true);

  // Fetch game data function using useCallback to prevent unnecessary re-creations
  const fetchGameData = useCallback(async () => {
    try {
      console.log("Fetching game data for gameId:", gameId);
      const data = await fetchGame(gameId);
      if (!data) {
        setError("Game not found");
        setLoading(false);
        return;
      }

      // Parse transactions if they exist
      if (data.gameData?.transactions) {
        data.gameData.transactions =
          typeof data.gameData.transactions === "string"
            ? JSON.parse(data.gameData.transactions)
            : data.gameData.transactions;
      }

      // Ensure players data is properly formatted
      if (data.gameData?.players) {
        data.gameData.players = data.gameData.players.map((player) => ({
          ...player,
          net: parseFloat(player.net) || 0,
          buy_in: parseFloat(player.buy_in) || 0,
          final_balance: parseFloat(player.final_balance) || 0,
        }));
      }

      // Initialize state with existing values only if not already set
      if (
        Object.keys(buyIns).length === 0 ||
        Object.keys(finalBalances).length === 0
      ) {
        const initialBuyIns = {};
        const initialBalances = {};
        data.players.forEach((player) => {
          initialBuyIns[player.id] = player.buy_in || 0;
          initialBalances[player.id] = player.final_balance || 0;
        });
        setBuyIns(initialBuyIns);
        setFinalBalances(initialBalances);
      }

      setGame(data);
      setLoading(false);
      console.log("Game data fetched and state updated:", data);
    } catch (error) {
      console.error("Error fetching game:", error);
      setError("Failed to fetch game data");
      setLoading(false);
    }
  }, [fetchGame, gameId, buyIns, finalBalances]);

  // Fetch game data on component mount and when game status changes
  useEffect(() => {
    if (polling) {
      fetchGameData();
    }

    // Only poll if not in balance entry state
    let interval;
    if (game?.status !== "awaiting_balances" && polling) {
      console.log("Setting up polling for game data...");
      interval = setInterval(fetchGameData, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        console.log("Polling interval cleared.");
      }
    };
  }, [fetchGameData, game?.status, polling]);

  const handleCalculate = async () => {
    if (isCalculating) return;
    setIsCalculating(true);
    setError(null);

    console.log("Starting calculation with:", {
      buyIns,
      finalBalances,
      buyInsAsNumbers: Object.fromEntries(
        Object.entries(buyIns).map(([id, val]) => [id, parseFloat(val)])
      ),
      finalBalancesAsNumbers: Object.fromEntries(
        Object.entries(finalBalances).map(([id, val]) => [id, parseFloat(val)])
      ),
    });

    try {
      // Enhanced input validation with logging
      const validationResults = game.players.map((player) => {
        const buyIn = parseFloat(buyIns[player.id]);
        const finalBalance = parseFloat(finalBalances[player.id]);
        return {
          playerId: player.id,
          name: player.name,
          buyIn: {
            raw: buyIns[player.id],
            parsed: buyIn,
            isValid: !isNaN(buyIn) && buyIn >= 0,
          },
          finalBalance: {
            raw: finalBalances[player.id],
            parsed: finalBalance,
            isValid: !isNaN(finalBalance) && finalBalance >= 0,
          },
        };
      });

      console.log("Validation results:", validationResults);

      const invalidResults = validationResults.filter(
        (result) => !result.buyIn.isValid || !result.finalBalance.isValid
      );

      if (invalidResults.length > 0) {
        throw new Error(
          `Invalid amounts for players: ${invalidResults
            .map((r) => r.name)
            .join(", ")}`
        );
      }

      // Calculate net changes with logging
      const netChanges = game.players.map((player) => {
        const buyIn = parseFloat(buyIns[player.id]);
        const finalBalance = parseFloat(finalBalances[player.id]);
        const net = finalBalance - buyIn;

        console.log("Player calculation:", {
          name: player.name,
          buyIn,
          finalBalance,
          net,
        });

        return {
          id: player.id,
          name: player.name,
          net,
          buy_in: buyIn,
          final_balance: finalBalance,
          user_id: player.user_id,
        };
      });

      // Verify game balance with logging
      const totalNet = netChanges.reduce((sum, player) => sum + player.net, 0);
      console.log("Balance verification:", {
        totalNet,
        isBalanced: Math.abs(totalNet) <= 0.01,
      });

      if (Math.abs(totalNet) > 0.01) {
        throw new Error(
          "Total gains and losses don't match. Please check the amounts."
        );
      }

      // Calculate transactions
      const debtors = netChanges
        .filter((p) => p.net < 0)
        .sort((a, b) => a.net - b.net);
      const creditors = netChanges
        .filter((p) => p.net > 0)
        .sort((a, b) => b.net - a.net);
      const transactions = [];

      // Deep clone arrays for calculations
      const workingDebtors = JSON.parse(JSON.stringify(debtors));
      const workingCreditors = JSON.parse(JSON.stringify(creditors));

      while (workingDebtors.length > 0 && workingCreditors.length > 0) {
        const debtor = workingDebtors[0];
        const creditor = workingCreditors[0];
        const payment = Math.min(-debtor.net, creditor.net);

        if (payment > 0) {
          transactions.push({
            payer_id: debtor.user_id, // Ensure this maps to the correct user ID
            payee_id: creditor.user_id, // Ensure this maps to the correct user ID
            amount: payment, // Numerical value
            description: `${debtor.name} owes ${creditor.name}: $${payment.toFixed(
              2
            )}`,
          });
          debtor.net += payment;
          creditor.net -= payment;

          if (Math.abs(debtor.net) < 0.01) workingDebtors.shift();
          if (Math.abs(creditor.net) < 0.01) workingCreditors.shift();
        }
      }

      // Validation Logic
      const isValidTransactions = transactions.every(
        (tx) =>
          tx.payer_id &&
          tx.payee_id &&
          typeof tx.amount === "number" &&
          typeof tx.description === "string"
      );

      if (!isValidTransactions) {
        setError(
          "Invalid transaction data. Please check the transaction details."
        );
        return; // Prevent further execution
      }

      // Prepare gameData with transactions as objects
      const gameData = {
        balances: netChanges.reduce((acc, player) => {
          acc[player.id] = player.final_balance;
          return acc;
        }, {}),
        transactions,
        players: netChanges,
        gameId,
        creator_id: game.creator_id,
      };

      console.log("Submitting final data:", {
        gameData,
        netChangesTotal: netChanges.reduce((sum, p) => sum + p.net, 0),
        transactionsCount: transactions.length,
      });

      // Submit data and update game status
      const response = await submitFinalBalances(gameId, gameData);

      console.log("Response from submitFinalBalances:", response);

      if (response && response.game) {
        // Update local state with the updated game from the server
        setGame(response.game);
        console.log("Game status updated to:", response.game.status);
        // Stop polling since the status has changed
        setPolling(false);
      } else {
        // If the response doesn't contain the updated game, manually update the status
        setGame((prevGame) => ({
          ...prevGame,
          status: "displaying_transactions",
          gameData: {
            ...prevGame.gameData,
            players: netChanges,
            transactions,
            balances: gameData.balances,
          },
        }));
        console.log("Game status manually updated to 'displaying_transactions'");
        // Stop polling since the status has changed
        setPolling(false);
      }

      // Optionally, you can navigate or show a success message here
      // navigate(`/games/${gameId}/transactions`);
    } catch (error) {
      console.error("Error in handleCalculate:", error);
      setError(error.message || "Failed to calculate results");
    } finally {
      setIsCalculating(false);
    }
  };

  const handleCompleteGame = async () => {
    try {
      await updateGameStatus(gameId, { status: "completed" });
      setGame((prevGame) => ({
        ...prevGame,
        status: "completed",
      }));
      navigate("/");
    } catch (error) {
      console.error("Error completing game:", error);
      setError("Failed to complete the game");
      // Still try to navigate
      navigate("/");
    }
  };

  // Early returns for loading and error states
  if (loading) {
    return <div className="text-yellow-500">Loading game data...</div>;
  }

  if (!game) {
    return <div className="text-yellow-500">Game not found</div>;
  }

  const isCreator = String(user?.id) === String(game?.creator_id);

  if (!isCreator) {
    return (
      <div className="text-yellow-500">
        You are not authorized to submit balances for this game.
      </div>
    );
  }

  // Helper function to get player balance data
  const getPlayerBalance = (playerId) => {
    const playerData = game.gameData?.players.find((p) => p.id === playerId);
    return playerData || { buy_in: 0, final_balance: 0, net: 0 };
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <Card className="bg-green-900/50 border-yellow-500">
        <CardHeader>
          <CardTitle className="text-4xl font-bold text-yellow-500">
            Submit Final Balances
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Section for entering balances */}
            {game.status === "awaiting_balances" && (
              <div className="space-y-4">
                {game.players.map((player) => (
                  <div
                    key={player.id}
                    className="flex flex-col md:flex-row items-center gap-4"
                  >
                    <span className="flex-1 text-xl text-yellow-500">
                      {player.name}
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-lg text-yellow-500">Buy-In:</span>
                      <Input
                        type="number"
                        value={buyIns[player.id] || ""}
                        onChange={(e) =>
                          setBuyIns({
                            ...buyIns,
                            [player.id]: e.target.value,
                          })
                        }
                        className="w-24 text-lg text-yellow-500 bg-black/20 border-yellow-500"
                        placeholder="0.00"
                        min="0"
                        step="0.01"
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-lg text-yellow-500">
                        End Balance:
                      </span>
                      <Input
                        type="number"
                        value={finalBalances[player.id] || ""}
                        onChange={(e) =>
                          setFinalBalances({
                            ...finalBalances,
                            [player.id]: e.target.value,
                          })
                        }
                        className="w-32 text-lg text-yellow-500 bg-black/20 border-yellow-500"
                        placeholder="0.00"
                        min="0"
                        step="0.01"
                      />
                    </div>
                  </div>
                ))}
                <div className="mt-6 flex gap-4">
                  <Button
                    onClick={handleCalculate}
                    className={`bg-yellow-500 text-black hover:bg-yellow-400 text-lg ${
                      isCalculating ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isCalculating}
                  >
                    {isCalculating ? "Calculating..." : "Calculate Results"}
                  </Button>
                </div>
              </div>
            )}

            {/* Error Message */}
            {error && (
              <div className="mt-4 text-red-500 text-lg">
                <p>{error}</p>
              </div>
            )}

            {/* Section to display net results and transactions */}
            {game.status === "displaying_transactions" && game.gameData && (
              <div className="mt-6 space-y-6">
                <h2 className="text-2xl font-semibold text-yellow-500">
                  Net Results:
                </h2>
                <ul className="list-disc ml-5 text-lg text-yellow-500">
                  {game.players.map((player) => {
                    const balance = getPlayerBalance(player.id);
                    const net = parseFloat(balance.final_balance) - parseFloat(balance.buy_in);
                    return (
                      <li key={player.id}>
                        {/* Player Name */}
                        {player.name}: Net{" "}
                        <span
                          className={net >= 0 ? "text-green-400" : "text-red-400"}
                        >
                          {net >= 0 ? "Gain" : "Loss"}: $
                          {Math.abs(net).toFixed(2)}
                        </span>
                        {/* Display Venmo Handle */}
                        {player.user?.venmo_handle ? (
                          <a
                            href={`https://venmo.com/${player.user.venmo_handle.replace('@', '')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-2 text-sm text-blue-500 hover:underline"
                          >
                            {player.user.venmo_handle}
                          </a>
                        ) : (
                          <span className="ml-2 text-sm text-red-500">
                            No Venmo Handle
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <h2 className="text-2xl font-semibold mt-4 text-yellow-500">
                  Transactions:
                </h2>
                <ul className="list-disc ml-5 text-lg text-yellow-500">
                  {Array.isArray(game.gameData.transactions) &&
                  game.gameData.transactions.length > 0 ? (
                    game.gameData.transactions.map((transaction, index) => (
                      <li key={index}>
                        {transaction.description ||
                          `Transaction ${index + 1}`}
                      </li>
                    ))
                  ) : (
                    <li className="text-lg text-yellow-500">
                      {Array.isArray(game.gameData.transactions)
                        ? "No transactions to display"
                        : "No transactions available"}
                    </li>
                  )}
                </ul>
                <div className="mt-6 flex gap-4">
                  <Button
                    onClick={handleCompleteGame}
                    className="bg-yellow-500 text-black hover:bg-yellow-400 text-lg py-3 px-6"
                  >
                    Complete Game
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubmitBalances;
