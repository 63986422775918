import React, { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Loader2 } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { useToast } from '../ui/use-toast';

function ProtectedRoute({ children, requireAdmin = false }) {
  const { user, loading, error, isAuthenticated } = useAuth();
  const location = useLocation();
  const { toast } = useToast();
  const hasShownToastRef = useRef(false); // Prevent repeated toasts

  // Handle authentication-related toasts
  useEffect(() => {
    if (!isAuthenticated && !loading && !hasShownToastRef.current) {
      toast({
        title: "Authentication Required ♠️",
        description: "Please log in to access this page",
        variant: "destructive",
      });
      hasShownToastRef.current = true;
    }

    if (requireAdmin && user && !user.isAdmin && !hasShownToastRef.current) {
      toast({
        title: "Access Denied ♣️",
        description: "You don't have permission to access this page",
        variant: "destructive",
      });
      hasShownToastRef.current = true;
    }
  }, [isAuthenticated, loading, requireAdmin, user, toast]);

  // Show loading state while authentication is being checked
  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-green-900">
        <Card className="w-full max-w-md bg-black/50 border-yellow-500">
          <CardContent className="flex flex-col items-center justify-center p-6">
            <Loader2 className="w-12 h-12 text-yellow-500 animate-spin" />
            <p className="mt-4 text-yellow-500 font-bold">Loading...</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Handle authentication errors
  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-green-900">
        <Card className="w-full max-w-md bg-black/50 border-yellow-500">
          <CardContent className="flex flex-col items-center justify-center p-6">
            <div className="text-yellow-500 text-center">
              <p className="text-xl font-bold mb-2">Authentication Error</p>
              <p className="text-yellow-500/60">{error}</p>
              <Button
                onClick={() => window.location.reload()}
                className="mt-4 bg-yellow-500 text-black hover:bg-yellow-400"
              >
                Retry
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Redirect unauthenticated users to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Redirect non-admin users if admin access is required
  if (requireAdmin && !user?.isAdmin) {
    return <Navigate to="/" replace />;
  }

  // Render protected content if all checks pass
  return children;
}

export default ProtectedRoute;
