// src/components/auth/RegisterForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { useToast } from '../ui/use-toast';

function RegisterForm() {
  const navigate = useNavigate();
  const { register, isAuthenticated } = useAuth();
  const { toast } = useToast();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    venmo_handle: ''
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const validateForm = () => {
    const errors = {};
    
    if (formData.username.length < 3) {
      errors.username = 'Username must be at least 3 characters long';
    }
    
    if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }

    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      errors.email = 'Please enter a valid email address';
    }

    if (formData.venmo_handle && !formData.venmo_handle.startsWith('@')) {
      errors.venmo_handle = 'Venmo handle must start with @';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear specific field error when typing
    if (validationErrors[name]) {
      setValidationErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
    
    // Clear general error
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    // Validate the form locally
    if (!validateForm()) {
      toast({
        title: "Validation Error ♠️",
        description: "Please check your input and try again",
        variant: "destructive",
      });
      return;
    }
  
    setIsLoading(true);
  
    try {
      const { email, password, username, venmo_handle } = formData;
  
      // Call the registration function
      await register(email, password, username, venmo_handle);
  
      // Show success toast
      toast({
        title: "Welcome to Poker Debt Solver! ♠️",
        description: "Your account has been created successfully",
        variant: "success",
      });
  
      // Redirect to homepage 
      navigate('/');
    } catch (err) {
      console.error('Registration error:', err);
  
      // Extract error message from backend response
      const errorMessage =
        err.response?.data?.error || // Check for backend error message
        err.message ||              // Use generic error message if not provided
        'Failed to create account. Please try again.'; // Default fallback message
  
      // Set the error state
      setError(errorMessage);
  
      // Show failure toast with detailed error message
      toast({
        title: "Registration Failed ♣️",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };


  const renderInput = (name, label, type = 'text', placeholder, optional = false) => (
    <div className="space-y-2">
      <label htmlFor={name} className="text-sm font-medium text-yellow-500">
        {label} {optional && <span className="text-yellow-500/60">(Optional)</span>}
      </label>
      <Input
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        value={formData[name]}
        onChange={handleChange}
        required={!optional}
        disabled={isLoading}
        className={`w-full bg-black/20 border-yellow-500 text-yellow-500 placeholder-yellow-500/30 
          ${validationErrors[name] ? 'border-red-500' : ''}`}
      />
      {validationErrors[name] && (
        <p className="text-xs text-red-500">{validationErrors[name]}</p>
      )}
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-900/50">
      <Card className="w-full max-w-md bg-green-800 border-yellow-500 shadow-lg">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center text-yellow-500">Create an Account</CardTitle>
        </CardHeader>

        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            {renderInput('username', 'Username', 'text', 'Choose a username')}
            {renderInput('email', 'Email', 'email', 'Enter your email')}
            {renderInput('password', 'Password', 'password', 'Create a password')}
            {renderInput('venmo_handle', 'Venmo Handle', 'text', '@your-venmo-username', true)}

            {/* Agreement Statement */}
            <p className="text-sm text-yellow-500 text-center">
              By creating an account, you agree to our{' '}
              <Link to="/terms-of-service" className="underline hover:text-yellow-400">
                Terms of Service
              </Link>.
            </p>

            <Button
              type="submit"
              className="w-full bg-yellow-500 text-black hover:bg-yellow-400"
              disabled={isLoading}
            >
              {isLoading ? 'Creating Account...' : 'Create Account'}
            </Button>
          </form>
        </CardContent>

        <CardFooter>
          <div className="text-sm text-center w-full text-yellow-500">
            Already have an account?{' '}
            <Button
              variant="link"
              className="p-0 h-auto font-semibold text-yellow-500"
              onClick={() => navigate('/login')}
              disabled={isLoading}
            >
              Log in
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}

export default RegisterForm;
