// src/contexts/GameContext.jsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { gameService } from '../services/game.service';
import { useAuth } from './AuthContext';
import { useToast } from '../components/ui/use-toast';

const GameContext = createContext(null);

export const GameProvider = ({ children }) => {
    const { isAuthenticated, logout } = useAuth();
    const { toast } = useToast();
    const [currentGame, setCurrentGame] = useState(null);
    const [userGames, setUserGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Centralized error handler
    const handleError = useCallback((err, customMessage) => {
        console.error(customMessage, err);
        
        if (err.message === 'Authentication failed') {
            toast({
                title: "Session Expired ♠️",
                description: "Please log in again",
                variant: "destructive",
            });
            logout();
            return;
        }

        const errorMessage = err.message || customMessage;
        setError(errorMessage);
        
        toast({
            title: "Error ♣️",
            description: errorMessage,
            variant: "destructive",
        });

        throw err;
    }, [logout, toast]);

    // Create a new game
    const createGame = useCallback(async (gameData) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to create a game');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const newGame = await gameService.createGame(gameData);
            setCurrentGame(newGame);
            setUserGames(prev => [newGame, ...prev]);
            
            toast({
                title: "Game Created ♠️",
                description: "Your new game has been created successfully",
                variant: "success",
            });
            
            return newGame;
        } catch (err) {
            handleError(err, 'Failed to create game');
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError, toast]);

    // Join a game using join code
    const joinGame = useCallback(async (joinCode) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to join a game');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const game = await gameService.joinGame({ joinCode });
            setCurrentGame(game);
            setUserGames(prev => [game, ...prev]);
            
            toast({
                title: "Game Joined ♣️",
                description: "You've successfully joined the game",
                variant: "success",
            });
            
            return game;
        } catch (err) {
            handleError(err, 'Failed to join game');
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError, toast]);

    // Start the game
    const startGame = useCallback(async (gameId) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to start the game');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const updatedGame = await gameService.startGame(gameId);
            setCurrentGame(updatedGame);
            setUserGames(prev => 
                prev.map(game => game.id === gameId ? updatedGame : game)
            );
            
            toast({
                title: "Game Started ♦️",
                description: "The game has been started successfully",
                variant: "success",
            });
            
            return updatedGame;
        } catch (err) {
            handleError(err, 'Failed to start game');
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError, toast]);

    // Fetch user's games
    const fetchUserGames = useCallback(async () => {
        if (!isAuthenticated) {
            setUserGames([]);
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const games = await gameService.getUserGames();
            setUserGames(games || []);
            return games;
        } catch (err) {
            handleError(err, 'Failed to fetch games');
            setUserGames([]);
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError]);

    // Fetch specific game
    const fetchGame = useCallback(async (gameId) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to view this game');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const game = await gameService.getGameById(gameId);
            setCurrentGame(game);
            return game;
        } catch (err) {
            handleError(err, 'Failed to fetch game');
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError]);

    // Update game status
    const updateGameStatus = useCallback(async (gameId, payload) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to update game status');
            return;
        }
        try {
            const updatedGame = await gameService.updateGameStatus(gameId, payload);
            setCurrentGame(updatedGame);
            setUserGames(prev => 
                prev.map(game => game.id === gameId ? updatedGame : game)
            );
            return updatedGame;
        } catch (err) {
            handleError(err, 'Failed to update game status');
        }
    }, [isAuthenticated, handleError]);

    const submitFinalBalances = useCallback(async (gameId, gameData) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to submit final balances');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const updatedGame = await gameService.submitFinalBalances(gameId, gameData);
            setCurrentGame(updatedGame);
            setUserGames(prev => 
                prev.map(game => game.id === gameId ? updatedGame : game)
            );
            
            toast({
                title: "Final Balances Submitted ♠️",
                description: "Final balances have been submitted successfully.",
                variant: "success",
            });
            
            return updatedGame;
        } catch (err) {
            handleError(err, 'Failed to submit final balances');
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError, toast, fetchGame]);


    // Update player balance
    const updatePlayerBalance = useCallback(async (gameId, playerId, balance) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to update balance');
            return;
        }
        try {
            const response = await gameService.updatePlayerFinalBalance(gameId, playerId, balance);
            return response;
        } catch (err) {
            handleError(err, 'Failed to update player balance');
        }
    }, [isAuthenticated, handleError]);

    // End game
    const endGame = useCallback(async (gameId) => {
        if (!isAuthenticated) {
            handleError(new Error('Authentication required'), 'Please log in to end the game');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const finalizedGame = await gameService.endGame(gameId);
            setCurrentGame(finalizedGame);
            setUserGames(prev => 
                prev.map(game => game.id === gameId ? finalizedGame : game)
            );
            
            toast({
                title: "Game Ended ♥️",
                description: "The game has been ended successfully",
                variant: "success",
            });
            
            return finalizedGame;
        } catch (err) {
            handleError(err, 'Failed to end game');
        } finally {
            setLoading(false);
        }
    }, [isAuthenticated, handleError, toast]);

    // Clear current game
    const clearCurrentGame = useCallback(() => {
        setCurrentGame(null);
    }, []);

    // Clear error
    const clearError = useCallback(() => {
        setError(null);
    }, []);

    const value = {
        currentGame,
        userGames,
        loading,
        error,
        createGame,
        joinGame,
        startGame,
        fetchUserGames,
        fetchGame,
        endGame,
        clearCurrentGame,
        clearError,
        updateGameStatus,
        updatePlayerBalance,
        submitFinalBalances
    };

    return (
        <GameContext.Provider value={value}>
            {children}
        </GameContext.Provider>
    );
};

// Custom hook for using the game context
export const useGame = () => {
    const context = useContext(GameContext);
    if (context === null) {
        throw new Error('useGame must be used within a GameProvider');
    }
    return context;
};

export default GameContext;