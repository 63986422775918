import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { ArrowLeft, Save } from 'lucide-react';
import { useToast } from '../components/ui/use-toast';
import { motion } from 'framer-motion';
import axios from '../utils/axios';
import { predefinedProfilePictures } from '../constants/profilePictures';

const SelectProfilePicture = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const getImageUrl = (path) => {
    if (!path) return '';
    const cleanPath = path.replace(/^\/+/, '');
    return `${axios.defaults.baseURL}/${cleanPath}`;
  };

  const handleSelection = (imagePath) => {
    // Store only the relative path
    const cleanPath = imagePath.replace(/^\/+/, '');
    setSelectedImage(cleanPath);
  };

  const handleSave = async () => {
    if (!selectedImage) {
      toast({
        title: "Error",
        description: "Please select a profile picture first",
        variant: "destructive",
      });
      return;
    }
  
    if (!user?.id) {
      toast({
        title: "Error",
        description: "User not found. Please try logging in again.",
        variant: "destructive",
      });
      return;
    }
  
    setLoading(true);
  
    try {
      // Make sure we're sending all user data
      const response = await axios.put(
        `/api/users/${user.id}`,
        {
          profile_picture: selectedImage,
          username: user.username,
          venmo_handle: user.venmo_handle,
          email: user.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      // Log the response to verify the data
      console.log('Update response:', response.data);
  
      // Make sure we're updating the full user object
      if (response.data?.user) {
        updateUser(response.data.user);
      }
  
      toast({
        title: "Success",
        description: "Profile picture updated successfully",
        variant: "success",
      });
      navigate('/profile/manage');
    } catch (error) {
      console.error('Error updating profile picture:', error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to update profile picture",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  // Check if an image is selected
  const isSelected = (imagePath) => {
    const cleanCurrentPath = imagePath.replace(/^\/+/, '');
    const cleanSelectedPath = selectedImage?.replace(/^\/+/, '');
    return cleanCurrentPath === cleanSelectedPath;
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className="dashboard-wrapper bg-black"
    >
      <div className="dashboard-container">
        <motion.nav 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="w-full mb-8 px-4"
        >
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            className="bg-poker-yellow text-poker-black hover:bg-poker-gold hover:text-black transition-colors duration-200 font-semibold w-full md:w-auto flex items-center justify-center gap-2"
          >
            <ArrowLeft className="w-4 h-4" />
            Back
          </Button>
        </motion.nav>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Card className="dashboard-card max-w-4xl mx-auto">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle className="card-title text-poker-gold">
                Select a Profile Picture
              </CardTitle>
              <Button
                onClick={handleSave}
                disabled={!selectedImage || loading}
                className="bg-poker-gold text-black hover:bg-poker-yellow disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 font-semibold flex items-center justify-center gap-2"
              >
                <Save className="w-4 h-4" />
                {loading ? 'Saving...' : 'Save Selection'}
              </Button>
            </CardHeader>
            <CardContent>
              <motion.div 
                className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {predefinedProfilePictures.options.map((image, index) => (
                  <motion.div
                    key={index}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.1 * index }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`relative group cursor-pointer ${
                      loading ? 'opacity-50 pointer-events-none' : ''
                    }`}
                    onClick={() => handleSelection(image)}
                  >
                    <div className="absolute inset-0 bg-poker-gold rounded-full opacity-0 group-hover:opacity-20 transition-opacity duration-200" />
                    <div className={`border-2 ${
                      isSelected(image) ? 'border-poker-yellow' : 'border-poker-gold'
                    } rounded-full p-2 transition-colors duration-200 group-hover:border-poker-yellow`}>
                      <img
                        src={getImageUrl(image)}
                        alt={`Avatar ${index + 1}`}
                        className="w-full aspect-square rounded-full object-cover"
                      />
                    </div>
                    {isSelected(image) && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 rounded-full"
                      >
                        <div className="w-4 h-4 bg-poker-yellow rounded-full" />
                      </motion.div>
                    )}
                  </motion.div>
                ))}
              </motion.div>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default SelectProfilePicture;