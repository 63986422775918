// components/PokerChipButton.jsx
import React from 'react';
import { Button } from './button';
import { motion } from 'framer-motion';

const PokerChipButton = ({ 
  children, 
  onClick, 
  variant = 'red',
  className = '', 
  disabled = false 
}) => {
  const chipColors = {
    red: '!bg-red-600 before:!border-red-400 after:!border-red-800',
    green: '!bg-green-600 before:!border-green-400 after:!border-green-800',
    blue: '!bg-blue-600 before:!border-blue-400 after:!border-blue-800'
  };

  const baseStyles = `
    !font-['Rye']
    !relative
    !flex
    !items-center
    !justify-center
    !p-4
    !rounded-full
    !font-semibold
    !shadow-lg
    !transition-all
    !overflow-hidden
    !w-full
    !mt-4
    !text-xl
    !text-white
    !border-0
  `;

  const chipPattern = `
    before:content-['']
    before:absolute
    before:inset-[4px]
    before:rounded-full
    before:border-[6px]
    before:border-dashed
    before:border-opacity-30

    after:content-['']
    after:absolute
    after:inset-[10px]
    after:rounded-full
    after:border-[3px]
    after:border-solid
    after:border-opacity-20

    [&>span]:relative
    [&>span]:z-10
  `;

  return (
    <motion.div
      whileTap={{
        rotateY: 180,
        transition: { duration: 0.3 }
      }}
      className="!perspective-1000"
    >
      <Button
        onClick={onClick}
        disabled={disabled}
        className={`
          ${baseStyles}
          ${chipPattern}
          ${chipColors[variant]}
          ${className}
          disabled:!opacity-50
          disabled:!cursor-not-allowed
          !shadow-[0_4px_12px_rgba(0,0,0,0.5)]
          hover:!shadow-[0_6px_16px_rgba(0,0,0,0.6)]
          hover:!brightness-110
          hover:-!translate-y-0.5
        `.trim()}
      >
        <span>{children}</span>
      </Button>
    </motion.div>
  );
};

export default PokerChipButton;