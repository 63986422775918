import React from 'react';

export const Button = ({ 
  children, 
  variant = 'default', 
  className = '', 
  ...props 
}) => {
  const baseStyles = 'px-4 py-2 rounded-lg font-medium transition-colors text-center';
  const variants = {
    default: 'bg-yellow-500 hover:bg-yellow-400 text-black font-bold',
    outline: 'border border-yellow-500 text-yellow-500 hover:bg-yellow-500/10',
    green: 'bg-green-700 hover:bg-green-600 text-white border border-yellow-500/50'
  };

  return (
    <button 
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};